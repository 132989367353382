import { Box, Typography } from "@mui/material";
import { EXAM_MAP_0, EXAM_MAP_1, EXAM_MAP_2 } from "enums/examMAP";
import React from "react";

export default function ExamMapType({ type }) {
  const inputType = (type) => {
    switch (type) {
      case "1":
        return { content: EXAM_MAP_1, bgcolor: "#EAE4F2", color: "#6940A5" };
      case "2":
        return { content: EXAM_MAP_2, bgcolor: "#FBF3DB", color: "#d09f00" };
      default:
        return { content: EXAM_MAP_0, bgcolor: "#DDEDEA", color: "#0F7B6C" };
    }
  };
  return (
    <Box width="90%" mx="auto">
      <Typography
        variant="body2"
        bgcolor={inputType(type).bgcolor}
        color={inputType(type).color}
        py={0.2}
        borderRadius={0.5}
        fontWeight={700}
      >
        {inputType(type).content || "-"}
      </Typography>
    </Box>
  );
}
