import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  List,
  ListItem,
  ListItemText,
  Popover,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { checkIcon, errorIcon, questionIcon } from "assets/icon/Icon_modal";
import { alertModalConfig, confirmModalConfig } from "components/templates/modalConfig";
import { MESSAGE_SEND_INFORMATION } from "enums/modalMessage";
import useMutationHook from "hooks/useMutationHook";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useRecoilValue } from "recoil";
import { groupInfoAtom } from "recoil/store";
import Swal from "sweetalert2";
// Sample user data
const SendInformation = ({ onClose, testers }) => {
  const groupInfoDetail = useRecoilValue(groupInfoAtom);
  const { mutate: sendInformation } = useMutationHook("sendInformation", "HR");
  const {
    control,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      target: "ALL",
      noticeType: "ALL",
      noticeMessage: {
        email: `1. 노트북 또는 컴퓨터로 진행(태블릿 PC, 모바일에서는 정상 구동이 안될 수 있음 <br/> 2. 최신 버전의 크롬브라우저 (권장) <br/> 3. 유선 인터넷 환경 (무선 인터넷 사용 시 끊김 발생할 수 있음) <br/> 4. 신분증 이미지 필요 (검사 시작 전 시스템 업로드)`,
        sms: `#{companyName} 인성검사 안내 \n입사 지원서에 기재한 메일을 확인해 주시기 바랍니다. \n감사합니다.`,
      },
      selectedUsers: [],
    },
  });

  const target = watch("target");
  const questionTextModules = {
    toolbar: [[{ header: [false] }], ["bold", "italic", "underline", "strike"], [{ list: "ordered" }, { list: "bullet" }], ["link"], ["clean"]],
  };

  const formats = ["header", "bold", "italic", "underline", "strike", "blockquote", "list", "bullet", "indent", "link", "image"];
  // Popover state
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const handleRadioChange = (event) => {
    if (event.target.value === "SPECIFIC") {
      // Save the current target as anchor element for the Popover
      setAnchorEl(event.currentTarget);
    }
  };

  // 선택 라디오 버튼 영역 클릭 핸들러 추가
  const handleSpecificLabelClick = (event) => {
    if (target === "SPECIFIC") {
      setAnchorEl(event.currentTarget);
    }
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const handleUserToggle = (userId) => {
    setSelectedUsers((prev) => {
      if (prev.includes(userId)) {
        return prev.filter((id) => id !== userId);
      } else {
        return [...prev, userId];
      }
    });
  };

  const handleConfirmSelection = () => {
    handlePopoverClose();
  };

  const onSubmit = (data) => {
    let modifiedData = { ...data };

    // Adjust noticeMessage based on noticeType
    if (data.noticeType === "EMAIL") {
      modifiedData.noticeMessage = {
        email: data.noticeMessage.email,
        sms: null,
      };
    } else if (data.noticeType === "SMS") {
      modifiedData.noticeMessage = {
        email: null,
        sms: data.noticeMessage.sms,
      };
    }

    Swal.fire(
      confirmModalConfig({
        text: MESSAGE_SEND_INFORMATION,
        title: "안내메세지 전송",
        iconHtml: questionIcon,
        confirmButtonText: "확인",
        cancelButtonText: "취소",
      })
    ).then((result) => {
      if (result.isConfirmed) {
        const formData = {
          ...modifiedData,
          selectedUsers: data.target === "SPECIFIC" ? selectedUsers : null,
        };

        sendInformation(
          {
            body: { testerIdxs: formData.selectedUsers, noticeMessage: formData.noticeMessage },
            groupIdx: groupInfoDetail.groupIdx,
            target: formData.target,
            noticeType: formData.noticeType,
          },
          {
            onSuccess: () => {
              Swal.fire(
                alertModalConfig({
                  text: "안내메세지 전송이 완료되었습니다.",
                  title: "안내메세지 전송",
                  iconHtml: checkIcon,
                  confirmButtonText: "확인",
                })
              ).then((result) => {
                if (result.isConfirmed) {
                  reset();
                  onClose();
                }
              });
            },
            onError: () => {
              Swal.fire(
                alertModalConfig({
                  text: "안내메세지 전송을 실패하였습니다.",
                  title: "안내메세지 전송",
                  iconHtml: errorIcon,
                  confirmButtonText: "확인",
                })
              );
            },
          }
        );
      }
    });

    // Handle form submission logic here
    // Handle form submission logic here
  };

  const open = Boolean(anchorEl);
  const id = open ? "user-selection-popover" : undefined;
  return (
    <>
      <DialogTitle mb={1}>
        {/* <Typography variant="h5" fontWeight="bold"> */}
        응시자 안내 메세지 전송
        {/* </Typography> */}
      </DialogTitle>

      <DialogContent>
        <Stack gap={2} mb={3}>
          <FormControl>
            <FormLabel>안내 대상</FormLabel>
            <Controller
              name="target"
              control={control}
              render={({ field }) => (
                <RadioGroup
                  row
                  {...field}
                  onChange={(e) => {
                    field.onChange(e);
                    handleRadioChange(e);
                  }}
                >
                  <FormControlLabel value="ALL" control={<Radio />} label="전체 일괄발송" />
                  <FormControlLabel value="SPECIFIC" control={<Radio />} onClick={handleSpecificLabelClick} label="선택" />
                </RadioGroup>
              )}
            />
          </FormControl>

          <FormControl>
            <FormLabel>안내 방법</FormLabel>
            <Controller
              name="noticeType"
              control={control}
              render={({ field }) => (
                <RadioGroup row {...field}>
                  <FormControlLabel value="ALL" control={<Radio />} label="메일 + SMS 안내" />
                  <FormControlLabel value="EMAIL" control={<Radio />} label="이메일 안내" />
                  <FormControlLabel value="SMS" control={<Radio />} label="SMS 안내" />
                </RadioGroup>
              )}
            />
          </FormControl>

          <Grid container alignItems={"flex-start"} columnGap={1} flexWrap={"nowrap"}>
            <FormControl sx={{ width: "60%" }}>
              <FormLabel sx={{ mb: 0.5 }}>이메일 안내 내용</FormLabel>
              <Controller
                name="noticeMessage.email"
                control={control}
                rules={{ required: "안내 내용을 입력해주세요" }}
                render={({ field }) => (
                  <ReactQuill
                    {...field}
                    value={field.value}
                    onChange={field.onChange}
                    modules={questionTextModules}
                    formats={formats}
                    className="sendInformation"
                    placeholder="안녕하세요. #{testerName}님, #{companyName}입니다. #{accessUrl}으로 접속해 주세요."
                    style={{ minHeight: "242px" }}
                  />
                )}
              />
            </FormControl>
            <FormControl sx={{ width: "40%" }}>
              <FormLabel sx={{ mb: 0.5 }}>SMS 안내 내용</FormLabel>

              <Controller
                name="noticeMessage.sms"
                control={control}
                rules={{ required: "안내 내용을 입력해주세요" }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    value={field.value}
                    onChange={field.onChange}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        fontSize: 13,
                        "& fieldset": {
                          borderColor: "oklch(0.923 0.003 48.717)",
                        },
                        "&:hover fieldset": {
                          borderColor: "oklch(0.923 0.003 48.717)",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "oklch(0.88 0.003 48.717)", // 포커스 시 살짝 어두운 색상
                          boxShadow: "none", // focus ring 제거
                        },
                        "&.Mui-focused": {
                          boxShadow: "none", // 추가적으로 focus ring 제거
                        },
                      },
                    }}
                    multiline
                    rows={11}
                    placeholder="SMS 안내 내용을 입력해주세요."
                  />
                )}
              />
            </FormControl>
          </Grid>
          <Stack gap={1}>
            <Typography>{`선택입력`}</Typography>
            <Typography color={"oklch(0.374 0.01 67.558)"}>{`· #{testerName}은 응시자 이름으로 변환되어 전송됩니다.`}</Typography>
            <Typography color={"oklch(0.374 0.01 67.558)"}>{`· #{accessUrl}은 응시자 전용 링크로 변환되어 전송됩니다.`}</Typography>
            <Typography color={"oklch(0.374 0.01 67.558)"}>{`· #{companyName}은 고객사명으로 변환되어 전송됩니다.`}</Typography>
          </Stack>
        </Stack>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handlePopoverClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <Box sx={{ p: 2, width: 370, maxHeight: 400 }}>
            <Typography fontSize={13} gutterBottom>
              응시자 선택
            </Typography>
            <List sx={{ maxHeight: 300, overflow: "auto" }}>
              {testers.data?.map((tester) => (
                <ListItem key={tester.testerIdx} dense button onClick={() => handleUserToggle(tester.testerIdx)} disableRipple>
                  <Checkbox edge="start" checked={selectedUsers.includes(tester.testerIdx)} tabIndex={-1} disableRipple />
                  <ListItemText primary={`${tester.testerName}`} secondary={`${tester.testerBirth} | ${tester.testerEmail} | ${tester.testerCell} `} />
                </ListItem>
              ))}
            </List>
            <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
              <Button onClick={handlePopoverClose} sx={{ mr: 1 }} variant="outlined">
                취소
              </Button>
              <Button variant="contained" color="primary" onClick={handleConfirmSelection}>
                확인
              </Button>
            </Box>
          </Box>
        </Popover>
      </DialogContent>
      <DialogActions sx={{ pb: 3, pl: 3 }}>
        <Button variant={"contained"} onClick={handleSubmit(onSubmit)}>
          전송하기
        </Button>
        <Button variant={"outlined"} onClick={onClose}>
          닫기
        </Button>
      </DialogActions>
    </>
  );
};

export default SendInformation;
