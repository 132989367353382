import { useEffect, useRef, useState } from "react";

// Style
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";

// React-query
import useGetQueryhr from "hooks/useGetQueryhr";

// Library
import { Korean } from "flatpickr/dist/l10n/ko.js";
import "flatpickr/dist/themes/airbnb.css";
import Flatpickr from "react-flatpickr";

// Utils
import { timeFormat } from "utils/formatter/dateFormat";
import { EXAM_MAP_0, EXAM_MAP_1, EXAM_MAP_2 } from "enums/examMAP";

export default function SearchBar({
  setParamValues,
  setSearchFilter,
  searchFilter,
  refetch,
}) {
  const [companyIdx, setCompanyIdx] = useState(-1);
  const {
    data: companyList = [],
    isLoading: companyList_isLoading,
    isFetching: companyList_isFetching,
  } = useGetQueryhr("getTotalCompany_CJ", null, true, false);

  const [companySelectItem, setCompanySelectItem] = useState([]);

  useEffect(() => {
    companyList &&
      setCompanySelectItem([
        ...[{ companyIdx: -1, companyName: "전체" }],
        ...companyList,
      ]);
  }, [companyList]);

  useEffect(() => {
    companySelectItem && setCompanyIdx(searchFilter.companyIdx);
  }, [searchFilter, companySelectItem]);

  const searchRef = useRef();

  const handleSubmitValues = (e) => {
    refetch();
    const { name, value } = e.target;
    setSearchFilter((prev) => ({ ...prev, [name]: value }));
  };

  const handleCompanyIdx = (e) => {
    setSearchFilter((prev) => ({ ...prev, companyIdx: e.target.value }));
    setCompanyIdx(e.target.value);
  };

  const removeBlankKeys = (input) => {
    const temp = Object.assign({}, input);

    for (const key in temp) {
      if (key === "" || temp[key] === "") delete temp[key];
      if (key === "roomUseYN") {
        if (temp[key] === "TOTAL") delete temp[key];
      }
      if (key === "companyIdx") {
        if (temp[key] === -1) delete temp[key];
      }
      if (key === "examMAP") {
        if (temp[key] === "TOTAL") delete temp[key];
      }
      if (key === "sExamDate" || key === "eExamDate")
        temp[key] = timeFormat(new Date(temp[key]));
    }
    return temp;
  };

  const submitSearch = () => {
    let submit = {};
    submit = { ...searchFilter, groupName: searchRef.current.value };
    const result = removeBlankKeys(submit);
    setParamValues(result);
  };

  const handleDate = ([dateFrom, dateTo]) => {
    if (dateFrom?.getTime() === dateTo?.getTime()) {
      let days = 1 * 24 * 60 * 60 * 1000;
      dateTo.setTime(dateTo.getTime() + days);

      setSearchFilter((prev) => ({
        ...prev,
        sExamDate: dateFrom,
        eExamDate: dateTo,
      }));
    } else {
      setSearchFilter((prev) => ({
        ...prev,
        sExamDate: dateFrom,
        eExamDate: dateTo,
      }));
    }
  };

  return (
    <>
      <Grid container mb={2} columnGap={1}>
        <Flatpickr
          className={"datepickerInput"}
          value={[searchFilter?.sExamDate, searchFilter?.eExamDate]}
          placeholder="기간별 검색"
          options={{
            locale: Korean,
            mode: "range",
          }}
          onChange={handleDate}
        />

        <TextField
          select
          label="회사명"
          size="small"
          name="companyIdx"
          onChange={handleCompanyIdx}
          value={companyIdx || -1}
          sx={{ minWidth: 120 }}
          SelectProps={{
            MenuProps: {
              sx: {
                maxHeight: 450,
              },
            },
          }}
          children={
            companySelectItem &&
            companySelectItem.map((item, index) => {
              return (
                <MenuItem key={index} value={item.companyIdx}>
                  {item.companyName}
                </MenuItem>
              );
            })
          }
        />

        <TextField
          select
          label="감독 방식"
          size="small"
          name="roomUseYN"
          onChange={handleSubmitValues}
          value={searchFilter?.roomUseYN || "TOTAL"}
          sx={{ minWidth: 110 }}
          children={[
            <MenuItem key={"total"} value={"TOTAL"}>
              전체
            </MenuItem>,
            <MenuItem key={"Y"} value={"Y"}>
              화상 감독
            </MenuItem>,
            <MenuItem key={"N"} value={"N"}>
              무감독
            </MenuItem>,
          ]}
        />

        <TextField
          select
          label="검사유형"
          size="small"
          name="examMAP"
          onChange={handleSubmitValues}
          value={searchFilter?.examMAP || "TOTAL"}
          sx={{ minWidth: 110 }}
          children={[
            <MenuItem key={"total"} value={"TOTAL"}>
              전체
            </MenuItem>,
            <MenuItem key={0} value={"0"}>
              {EXAM_MAP_0}
            </MenuItem>,
            <MenuItem key={2} value={"2"}>
              {EXAM_MAP_2}
            </MenuItem>,
            <MenuItem key={1} value={"1"}>
              {EXAM_MAP_1}
            </MenuItem>,
          ]}
        />

        <Grid item>
          <TextField
            size="small"
            inputRef={searchRef}
            placeholder="공고명을 입력해 주세요."
          />
        </Grid>
        <Grid item>
          <Button
            sx={{ height: "100%" }}
            variant={"contained"}
            onClick={submitSearch}
          >
            검색
          </Button>
        </Grid>
      </Grid>
    </>
  );
}
