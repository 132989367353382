import { Box, Typography } from "@mui/material";
export const aiLevel = (input) => {
  switch (true) {
    case input < 50:
      return (
        <Typography
          variant="body2"
          bgcolor="#EEF3ED"
          color="#548064"
          py={0.4}
          borderRadius={0.5}
          fontWeight={700}
        >
          {`양호 (${input})`}
        </Typography>
      );
    case input < 100:
      return (
        <Typography
          variant="body2"
          bgcolor="#FAF3DD"
          color="#C29243"
          py={0.4}
          borderRadius={0.5}
          fontWeight={700}
        >
          {`주의 (${input})`}
        </Typography>
      );
    case input < 150:
      return (
        <Typography
          variant="body2"
          bgcolor="#F8ECDF"
          color=" #CC772F"
          py={0.4}
          borderRadius={0.5}
          fontWeight={700}
        >
          {`위험 (${input})`}
        </Typography>
      );
    case input >= 150:
      return (
        <Typography
          variant="body2"
          bgcolor="#FAECEC"
          color="#C4554D"
          py={0.4}
          borderRadius={0.5}
          fontWeight={700}
        >
          {`매우 위험 (${input})`}
        </Typography>
      );
  }
};
