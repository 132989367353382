import { Box, Typography } from "@mui/material";
export const aiType = (input) => {
  switch (input) {
    case "응시자 미감지":
      return (
        <Typography
          variant="body2"
          bgcolor="#F3EEEE"
          color="#824728"
          py={0.4}
          borderRadius={0.5}
          fontWeight={700}
        >
          {input}
        </Typography>
      );
    case "소음감지":
      return (
        <Typography
          variant="body2"
          bgcolor="#FAF3DD"
          color="#C29243"
          py={0.4}
          borderRadius={0.5}
          fontWeight={700}
        >
          {input}
        </Typography>
      );
    case "타인 감지":
      return (
        <Typography
          variant="body2"
          bgcolor="#F9F2F5"
          color=" #B35588"
          py={0.4}
          borderRadius={0.5}
          fontWeight={700}
        >
          {input}
        </Typography>
      );
    case "의심 행동 감지":
      return (
        <Typography
          variant="body2"
          bgcolor="#F6F3F8"
          color="#A48BBE"
          py={0.4}
          borderRadius={0.5}
          fontWeight={700}
        >
          {input}
        </Typography>
      );
    default:
      return (
        <Typography
          variant="body2"
          bgcolor="#E9F3F7"
          color="#477DA5"
          py={0.4}
          borderRadius={0.5}
          fontWeight={700}
        >
          {input}
        </Typography>
      );
  }
};
