export const REQUIRE_VALUE = "* 필수 입력값입니다.";
export const MIN_LENGTH = "최소 입력 글자수는 8자입니다.";
export const MAX_LENGTH = "최대 입력 글자수는 15자입니다.";
export const WRONG_VALUE_PW = "비밀번호 형식을 다시 확인해 주세요.";
export const WRONG_VALUE_EMAIL = "비밀번호 형식을 다시 확인해 주세요.";
export const VALID_VALUE = {
  PW: {
    REG_EXP:
      /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$ %^&*-]).{8,15}$/,
    MESSAGE: "비밀번호 형식을 다시 확인해 주세요.",
  },

  EMAIL: {
    REG_EXP: /^[a-zA-Z0-9+-\_.]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
    MESSAGE: "이메일 형식이 올바르지 않습니다",
  },

  BIRTH: {
    REG_EXP:
      /^(19[0-9][0-9]|20\d{2})(0[0-9]|1[0-2])(0[1-9]|[1-2][0-9]|3[0-1])$/,
    MESSAGE: "생년월일 형식이 올바르지 않습니다",
  },

  PHONE: {
    REG_EXP: /^\d{3}-\d{3,4}-\d{4}$/,
    MESSAGE: "휴대전화 번호 형식이 올바르지 않습니다",
  },

  AUTH_KEY: {
    REG_EXP: /^[0-9]{6,6}$/,
    MESSAGE: "인증키 형식이 올바르지 않습니다",
  },

  NAME: {
    REG_EXP: /^[a-zA-Zㄱ-ㅎ|가-힣]+$/,
    MESSAGE: "입력 형식이 올바르지 않습니다",
  },
  ID: {
    REG_EXP: /^[a-zA-Z0-9]*$/,
    MESSAGE: "입력 형식이 올바르지 않습니다",
  },
};
