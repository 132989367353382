import CompanyList from "components/pages/acg_admin/company/CompanyList";
import GroupList from "components/pages/acg_hr/group/main/GroupList";
import GroupListGuest from "components/pages/acg_hr_guest/group/main/GroupList";
import RoomList from "components/pages/acg_hr/group/main/roomList/RoomList";
import RoomListGuest from "components/pages/acg_hr_guest/group/main/roomList/RoomList";
import RoomTesterList from "components/pages/acg_hr/group/main/roomList/RoomTesterList";
import RoomTesterListGuest from "components/pages/acg_hr_guest/group/main/roomList/RoomTesterList";
import QnAList from "components/pages/acg_admin/QnA/QnAList";
import TesterList from "components/pages/acg_hr/group/main/testerList/TesterList";
import TesterListGuest from "components/pages/acg_hr_guest/group/main/testerList/TesterList";
import TemplateMain from "components/pages/acg_admin/company/modules/layoutDesign/TemplateMain";
import ManageTerms from "components/pages/acg_admin/terms/ManageTerms";
import HRManage from "components/pages/acg_hr/hr/HRManage";
import UpdateGroup from "components/pages/acg_hr/group/update/UpdateGroup";
import SearchTesters from "components/pages/acg_hr/hr/SearchTesters";
import SearchTestersGuest from "components/pages/acg_hr_guest/hr/SearchTesters";
import UpdateUser from "components/pages/signUp/HR/user/UpdateUser";
import { RoleBasedComponent } from "components/routesComponent/RoleBasedComponent";

export const groupRoutes = [
  { path: "/acghr_hr/:company/group", element: <RoleBasedComponent staffComponent={<GroupList />} guestComponent={<GroupListGuest />} /> },
  { path: "/acghr_hr/:company/roomList:id", element: <RoleBasedComponent staffComponent={<RoomList />} guestComponent={<RoomListGuest />} /> },
  {
    path: "/acghr_hr/:company/roomList/testerList:id",
    element: <RoleBasedComponent staffComponent={<RoomTesterList />} guestComponent={<RoomTesterListGuest />} />,
  },
  {
    path: "/acghr_hr/:company/update/:id",
    element: <UpdateGroup />,
  },
  {
    path: "/acghr_hr/:company/testerList:id",
    element: <RoleBasedComponent staffComponent={<TesterList />} guestComponent={<TesterListGuest />} />,
  },
  { path: "/acghr_hr/:company/searchTester", element: <RoleBasedComponent staffComponent={<SearchTesters />} guestComponent={<SearchTestersGuest />} /> },
  { path: "/acghr_hr/:company/updateUser", element: <UpdateUser /> },
  { path: "/acghr_hr/:company/manager", element: <HRManage /> },
  { path: "/acghr_hr/:company/qna", element: <QnAList /> },
];

export const companyRoutes = [
  { path: "/company", element: <CompanyList /> },
  { path: "/company/layout", element: <TemplateMain /> },
  { path: "/company/terms", element: <ManageTerms /> },
];

// /login?232323
