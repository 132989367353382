import { useEffect, useRef } from "react";

// Style
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import OutlinedInput from "@mui/material/OutlinedInput";
import Stack from "@mui/material/Stack";

// Recoil
import { useRecoilState } from "recoil";
import { searchTesterParams } from "recoil/store";

// Dialog

// React-query

export default function SearchTestersInput() {
  const [paramValues, setParamValues] = useRecoilState(searchTesterParams);

  const testerInputRef = useRef();

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      const { value } = testerInputRef.current;
      if (value === "") {
        setParamValues((prev) => ({ ...prev, pageNo: 1, testerInfo: value, isSearched: true }));
      } else {
        setParamValues((prev) => ({ ...prev, testerInfo: value, isSearched: true }));
      }
    }
  };

  const submitSearch = () => {
    const { value } = testerInputRef.current;

    if (value === "") {
      setParamValues((prev) => ({ ...prev, pageNo: 1, testerInfo: value, isSearched: true }));
    } else {
      setParamValues((prev) => ({ ...prev, testerInfo: value, isSearched: true }));
    }
    // setTesterName(value);
  };

  useEffect(() => {
    testerInputRef.current.value = paramValues.testerInfo;
    testerInputRef.current.focus();
  }, []);

  return (
    <>
      <Grid container columnGap={1} justifyContent="space-between">
        <Stack direction="row" columnGap={1}>
          <Grid item>
            <OutlinedInput
              size="small"
              inputRef={testerInputRef}
              placeholder={`성명 또는 휴대전화 번호를 입력해 주세요.`}
              onKeyUp={handleKeyPress}
              sx={{ width: 300 }}
            />
          </Grid>
          <Grid item>
            <Button variant="outlined" onClick={submitSearch}>
              검색
            </Button>
          </Grid>
        </Stack>
      </Grid>
    </>
  );
}
