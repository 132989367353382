import LoginPage from "components/pages/login/LoginPage";
import LoginPage_hr from "components/pages/login/LoginPage_hr";
import UpdateUser from "components/pages/signUp/HR/user/UpdateUser";
import { lazy, Suspense } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import Content from "./Content";

import PracticeDetail from "components/pages/acg_admin/practice/PracticeDetail";
import PracticeMain from "components/pages/acg_admin/practice/PracticeGroupMain";
import QuestionDetail from "components/pages/acg_admin/question/detail/QuestionDetail";
import QuestionGroupMain from "components/pages/acg_admin/question/QuestionGroupMain";
import SubjectDetail from "components/pages/acg_admin/subject/SubjectDetail";
import SubjectGroupMain from "components/pages/acg_admin/subject/SubjectGroupMain";
import SignUpHR from "components/pages/signUp/HR/signUpPages/SignUpHR";
import SignUpHRterm from "components/pages/signUp/HR/signUpPages/SignUpTerm";
import DetailStatsPage from "components/pages/stats/DetailStatsPage";
import ManagerList from "components/pages/stats/ManagerList";
import Stats from "components/pages/stats/Stats";
import TestersList from "components/pages/stats/TestersList";
import FallbackLoading from "components/UI/FallbackLoading";
import { companyRoutes, groupRoutes } from "utils/routers";
import ContentStats from "./ContentStats";

const SignUpSUPER = lazy(() => import("components/pages/signUp/SUPER/SignUpSUPER"));
const ManageTerms = lazy(() => import("components/pages/acg_admin/terms/ManageTerms"));
const ReportList = lazy(() => import("components/pages/acg_admin/report/ReportList"));

const NotFound = lazy(() => import("components/pages/error/notFound/NotFound"));
const ErrorPage = lazy(() => import("components/pages/error/networkError/ErrorPage"));

const MiddleRoute = () => {
  const location = useLocation();

  return (
    <Suspense fallback={<FallbackLoading />}>
      <Routes key={location.pathname}>
        <Route path="/login" element={<LoginPage />} />
        <Route path="/login_hr" element={<LoginPage_hr />} />
        <Route path="/signUp_hr" element={<SignUpHR />} />
        <Route path="/signUp_hr_term" element={<SignUpHRterm />} />
        <Route path="/signUp_super" element={<SignUpSUPER />} />
        <Route path="/" element={<Navigate replace to="/login" />} />

        {/* 통계 */}
        <Route element={<ContentStats />}>
          <Route path="/acghr_hr/:company/stats" element={<Stats />} />
          <Route path="/acghr_hr/:company/stats/manager" element={<ManagerList />} />
          <Route path="/acghr_hr/:company/stats/:id" element={<DetailStatsPage />} />
          <Route path="/acghr_hr/:company/stats/updateUser" element={<UpdateUser />} />
          <Route path="/acghr_hr/:company/stats/testers/:id" element={<TestersList />} />
        </Route>

        {/* //*ACG */}
        <Route element={<Content />}>
          {companyRoutes.map((route, index) => (
            <Route key={index} {...route} />
          ))}
          <Route path="/terms" element={<ManageTerms />} />
          <Route path="/report" element={<ReportList />} />
          <Route path="/practice" element={<PracticeMain />} />
          <Route path="/practice/:id" element={<PracticeDetail />} />
          <Route path="/question" element={<QuestionGroupMain />} />
          <Route path="/question/:id" element={<QuestionDetail />} />
          <Route path="/subject" element={<SubjectGroupMain />} />
          <Route path="/subject/:id" element={<SubjectDetail />} />
          {groupRoutes.map((route, index) => (
            <Route key={index} {...route} />
          ))}
          {/* {groupRoutes.map((route, index) => (
            <Route key={index} {...route} />
          ))} */}
        </Route>

        <Route path="*" element={<NotFound />} />
        <Route path="/error" element={<ErrorPage />} />
      </Routes>
    </Suspense>
  );
};

export default MiddleRoute;
