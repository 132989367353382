import React from "react";

import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";

const LoadingCircle = () => {
  return (
    <Grid
      container
      justifyContent={"center"}
      alignItems="center"
      p={4}
      height="100%"
    >
      <CircularProgress size={30} sx={{ color: "#adadad" }} />
    </Grid>
  );
};

export default LoadingCircle;
