import "./App.css";
import "./components/templates/modal.css";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { theme } from "./themes/theme";
import { QueryClientProvider, QueryClient } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import MiddleRoute from "./components/routesComponent/MiddleRoute";
import { RecoilRoot } from "recoil";
import ParentDialog from "components/templates/ParentDialog";
import { AnimatePresence } from "framer-motion";
import { DebugObserver } from "DebugObserver";

function App() {
  const queryClient = new QueryClient();

  return (
    <>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={theme}>
          <RecoilRoot>
            <DebugObserver />
            <CssBaseline />
            <ReactQueryDevtools initialIsOpen={false} position="right" />
            <ParentDialog />
            <AnimatePresence>
              <MiddleRoute />
            </AnimatePresence>
          </RecoilRoot>
        </ThemeProvider>
      </QueryClientProvider>
    </>
  );
}

export default App;
