import { Grid } from "@mui/material";
import React from "react";
import { ReactComponent as Spinner } from "assets/icon/SvgSpinnersBlocksWave.svg";
const FallbackLoading = () => {
  return (
    <Grid container justifyContent="center" alignItems="center" height="100vh">
      <Spinner />
    </Grid>
  );
};

export default FallbackLoading;
