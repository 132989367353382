import { lazy, Suspense, useCallback, useEffect, useReducer, useState } from "react";

// Style
import ContentCopyRoundedIcon from "@mui/icons-material/ContentCopyRounded";
import RemoveRoundedIcon from "@mui/icons-material/RemoveRounded";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Checkbox from "@mui/material/Checkbox";
import Dialog from "@mui/material/Dialog";
import Grid from "@mui/material/Grid";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Pagination from "@mui/material/Pagination";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { ReactComponent as AddTester } from "assets/icon/UsergroupAddOutlined.svg";
import EmptyPlaceholder from "components/UI/organism/EmptyPlaceholder";
import LoadingCircle from "components/UI/organism/LoadingCircle";
import LoadingSkeleton from "components/UI/organism/LoadingSkeleton";
import { tableHeader, tableWrapper } from "themes/theme";
import GroupCard from "../groupCards/GroupCard";
// Router
import { useNavigate, useParams } from "react-router-dom";

// Recoil
import { useRecoilValue, useSetRecoilState } from "recoil";
import { groupInfoAtom, HRcompanyInfo, roomInfoAtom, roomsAtom } from "recoil/store";

// React-query
import { useQueryClient } from "@tanstack/react-query";
import useGetQueryhr from "hooks/useGetQueryhr";
import useMutationHook from "hooks/useMutationHook";

// Hooks
import useGetFile from "hooks/useGetFile";

// Enums
import { GROUP_ROOM_LIST } from "enums/tableColumn";

import _ from "lodash";

import { Alert, Collapse, Fade, IconButton, ListItemIcon, Snackbar } from "@mui/material";
import { checkIcon, errorIcon, questionIcon } from "assets/icon/Icon_modal";
import { alertModalConfig, confirmModalConfig } from "components/templates/modalConfig";
import TopTitle from "components/templates/topTitle/TopTitle";
import {
  MESSAGE_ASSIGN_ROOM,
  MESSAGE_CREATE_ROOM,
  MESSAGE_DELETE_ROOM,
  MESSAGE_DOWNLOAD_AI_DATA,
  MESSAGE_DOWNLOAD_GROUP_REPORT,
  MESSAGE_SCORING,
} from "enums/modalMessage";
import {
  AlignStartVertical,
  BrainCircuit,
  ClipboardList,
  Component,
  Database,
  FileDown,
  FileSpreadsheet,
  FileText,
  FolderPlus,
  FunctionSquare,
  LayoutList,
  Mail,
  Upload,
  User2,
  Users2,
  UserSquare2,
  Video,
} from "lucide-react";
import { modalReducer, progressReducer } from "reducer/reducer";
import Swal from "sweetalert2";
import { checkboxSelect } from "utils/checkboxSelect";
import { maskingName, maskingPhoneNumber } from "utils/maskingInfo";
import { DownloadProgressModalMemoized } from "./DownloadProgressModalMemoized";
import DownloadScoreTestModal from "./DownloadScoreTestModal";

const COPY_SUCCESS_MESSAGE = "클립보드에 복사되었습니다.";
const COPY_FAIL_MESSAGE = "클립보드에 복사를 실패하였습니다.";

const CreateTesterModal = lazy(() => import("./CreateTesterModal"));
const CreateManagerModal = lazy(() => import("./CreateManagerModal"));
const SendMessageModal = lazy(() => import("./SendMessageModal"));
const DownloadTesterListModal = lazy(() => import("./DownloadTesterListModal"));
const DownloadGroupMarkDataModal = lazy(() => import("./DownloadGroupMarkDataModal"));

const TestSetStatus = lazy(() => import("./TestSetStatus"));
const TesterStatusListDownloadModal = lazy(() => import("./TesterStatusListDownloadModal"));
const UploadMarkDataModal = lazy(() => import("./UploadMarkDataModal"));

export default function RoomList() {
  const navigate = useNavigate();
  const { id, company } = useParams();

  const [selected, setSelected] = useState([]);
  const [downloadAnchorEl, setDownloadAnchorEl] = useState(null);
  const [downloadMarkDataAnchorEl, setDownloadMarkDataAnchorEl] = useState(null);
  const [reportMenuEl, setReportMenuEl] = useState(null);
  const queryClient = useQueryClient();

  const [paramValues, setParamValues] = useState({
    perPage: 1200,
  });
  const [markType, setMarkType] = useState("");
  const [copyResult, setCopyResult] = useState(false);
  const [isCopyError, setIsCopyError] = useState(false);
  const [copyResultMessage, setCopyResultMessage] = useState("");
  const handleCloseAlert = () => setCopyResult(!copyResult);

  const companyInfo = useRecoilValue(HRcompanyInfo);
  const groupInfoDetail = useSetRecoilState(groupInfoAtom);
  const setRoomInfo = useSetRecoilState(roomInfoAtom);
  const roomIdxs = useSetRecoilState(roomsAtom);

  const [downloadAIdata, setDownloadAIdata] = useState(false);

  const [state, dispatch] = useReducer(modalReducer, { modal: null });

  const [progressModalState, dispatchProgressModalState] = useReducer(progressReducer, { modal: null });

  const openDownloadMenu = Boolean(downloadAnchorEl);
  const openDownloadReportMenu = Boolean(reportMenuEl);
  const openDownloadMarkDataMenu = Boolean(downloadMarkDataAnchorEl);

  const handleOpenDownloadListMenu = (e) => setDownloadAnchorEl(e.currentTarget);
  const handleOpenDownloadReportListMenu = (e) => setReportMenuEl(e.currentTarget);
  const handleOpenDownloadMarkData = (e) => setDownloadMarkDataAnchorEl(e.currentTarget);

  const handleCloseDownloadListMenu = () => setDownloadAnchorEl(null);
  const handleCloseReportDownloadListMenu = () => setReportMenuEl(null);
  const handleCloseDownloadMarkData = () => setDownloadMarkDataAnchorEl(null);

  //* 해당 그룹 화상룸 상세정보 조회
  const { data: groupInfo, isLoading: groupInfoDataLoading, isError: groupInfoDataError } = useGetQueryhr("getGroupDetail", { groupIdx: id });

  //* 화상룸목록
  const { data, pageInfo, isFetching, isLoading, isError, refetch } = useGetQueryhr("roomList", { ...paramValues, ...{ index: id } });

  useEffect(() => {
    data && setRoomInfo(data);
    data && roomIdxs(data.data);
  }, [data, isFetching, isLoading]);

  useEffect(() => {
    groupInfo && groupInfoDetail(groupInfo);
  }, [groupInfo]);

  //* Mutation 후 이벤트
  const alertResult = useCallback((key) => {
    setSelected([]);
    queryClient.invalidateQueries({
      queryKey: [key],
    });
  }, []);

  //* 룸 일괄배정
  const { mutate: assignRoom } = useMutationHook("assignRoom", "HR");

  //* 개별 화상룸 추가
  const { mutate: addOneRoom } = useMutationHook("addOneRoom", "HR");

  //* 개별 화상룸 삭제
  const { mutate: deleteSelectRoom } = useMutationHook("deleteSelectRoom", "HR");

  //* 감독관 템플릿 다운로드
  useGetFile("HR", "downloadAiData", { groupIdx: Number(id) }, downloadAIdata, setDownloadAIdata);

  //* 전체 HR 레포트 탬플릿 생성
  const { mutateAsync } = useMutationHook("groupHRreport", "HR");

  //* CheckBox
  const handleCheckClick = (_, name) => {
    const { roomIdx } = name;
    const selectedIndex = selected.indexOf(roomIdx);
    setSelected(checkboxSelect(selected, selectedIndex, roomIdx));
  };

  //* 전체선택 체크박스
  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = data.data.map((n) => n.roomIdx);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const handleDeleteSelectRoom = () =>
    Swal.fire(
      confirmModalConfig({
        text: MESSAGE_DELETE_ROOM,
        title: "화상룸 삭제",
        iconHtml: questionIcon,
        confirmButtonText: "확인",
        cancelButtonText: "취소",
      })
    ).then((result) => {
      if (result.isConfirmed) {
        deleteSelectRoom(
          { groupIdx: Number(id), roomIdxList: selected },
          {
            onSuccess: () => {
              Swal.fire(
                alertModalConfig({
                  text: "화상룸 삭제가 완료되었습니다.",
                  title: "화상룸 삭제",
                  iconHtml: checkIcon,
                  confirmButtonText: "확인",
                })
              ).then((result) => {
                if (result.isConfirmed) alertResult("roomList");
              });
            },
          }
        );
      }
    });

  const handleAssignRoom = () => {
    Swal.fire(
      confirmModalConfig({
        html: MESSAGE_ASSIGN_ROOM,
        title: "화상룸 배정",
        iconHtml: questionIcon,
        confirmButtonText: "확인",
        cancelButtonText: "취소",
      })
    ).then((result) => {
      if (result.isConfirmed) {
        assignRoom(
          { groupIdx: Number(id) },
          {
            onSuccess: () => {
              Swal.fire(
                alertModalConfig({
                  text: "화상룸 배정이 완료되었습니다.",
                  title: "화상룸 배정",
                  iconHtml: checkIcon,
                  confirmButtonText: "확인",
                })
              ).then((result) => {
                if (result.isConfirmed) alertResult("roomList");
              });
            },
          }
        );
      }
    });
  };
  const handleScoring = () => {
    Swal.fire(
      confirmModalConfig({
        html: MESSAGE_SCORING,
        title: "응시 결과처리",
        iconHtml: questionIcon,
        confirmButtonText: "확인",
        cancelButtonText: "취소",
      })
    ).then((result) => {
      if (result.isConfirmed) {
        handleOpenProgressModal("scoreTest");
      }
    });
  };

  const handleAddOneRoom = () => {
    Swal.fire(
      confirmModalConfig({
        text: MESSAGE_CREATE_ROOM,
        title: "화상룸 추가",
        iconHtml: questionIcon,
        confirmButtonText: "확인",
        cancelButtonText: "취소",
      })
    ).then((result) => {
      if (result.isConfirmed) {
        addOneRoom(
          { groupIdx: Number(id) },
          {
            onSuccess: () => {
              Swal.fire(
                alertModalConfig({
                  text: "화상룸 추가가 완료되었습니다.",
                  title: "화상룸 추가",
                  iconHtml: checkIcon,
                  confirmButtonText: "확인",
                })
              ).then((result) => {
                if (result.isConfirmed) alertResult("roomList");
              });
            },
          }
        );
      }
    });
  };

  //* Dispatch State
  const handleOpenProgressModal = (modal) => {
    handleCloseDownloadListMenu();
    handleCloseReportDownloadListMenu();

    dispatchProgressModalState({
      type: !progressModalState.modal ? modal : "close-modal",
      payload: { modal },
    });
  };
  const handleOpenModal = (modal) => {
    handleCloseDownloadListMenu();
    dispatch({
      type: !state.modal ? modal : "close-modal",
      payload: { modal },
    });
  };
  const handleCloseModal = () => {
    dispatch({ type: "close-modal" });
  };

  const handleCloseProgressModal = () => {
    handleCloseDownloadListMenu();
    handleCloseReportDownloadListMenu();
    dispatchProgressModalState({ type: "close-modal" });
  };

  const handleDownloadGroupMarkData = (_, type) => {
    handleCloseDownloadListMenu();
    handleCloseDownloadMarkData();

    setMarkType(type);
    handleOpenModal(type);
  };

  const handleClickRow = (e, row) => {
    if (e.target.cellIndex >= 1) {
      navigate(`/acghr_hr/${company}/roomList/testerList${row.roomIdx}`, {
        state: { groupIdx: id, roomNo: row.roomNo, total: 1200 },
      });
    }
  };

  const handleDownloadAIdata = () => {
    handleCloseDownloadListMenu(); //* CLOSE DROPDOWN MENU
    Swal.fire(
      confirmModalConfig({
        text: MESSAGE_DOWNLOAD_AI_DATA,
        title: "AI 데이터 다운로드",
        iconHtml: questionIcon,
        confirmButtonText: "확인",
        cancelButtonText: "취소",
        focusConfirm: true,
        showLoaderOnConfirm: true,
      })
    ).then((result) => {
      if (result.isConfirmed) {
        setDownloadAIdata(!downloadAIdata);
      }
    });
  };

  const handleCreateGroupHRreport = () => {
    handleCloseDownloadListMenu(); //* CLOSE DROPDOWN MENU
    handleCloseReportDownloadListMenu();

    Swal.fire(
      confirmModalConfig({
        text: MESSAGE_DOWNLOAD_GROUP_REPORT,
        title: "레포트 다운로드",
        iconHtml: questionIcon,
        confirmButtonText: "확인",
        cancelButtonText: "취소",
        focusConfirm: true,
        showLoaderOnConfirm: true,
        allowOutsideClick: false,
        allowEscapeKey: false,
        preConfirm: async () => {
          try {
            const res = await mutateAsync({
              groupIdx: Number(id),
            });
            window.open(`https://${res.data.data}`);
            return true;
          } catch (error) {
            return false;
          }
        },
      })
    );
  };

  const handleCopyClipBoard = async (text) => {
    try {
      setCopyResult(true);
      await navigator.clipboard.writeText(text);
      setCopyResultMessage(COPY_SUCCESS_MESSAGE);
    } catch (e) {
      setIsCopyError(true);
      setCopyResultMessage(COPY_FAIL_MESSAGE);
    }
  };

  return (
    <>
      <Box width="100%" height="100%" borderRadius={2} display="flex" flexDirection="column" rowGap={3}>
        <Grid container justifyContent={"space-between"}>
          <TopTitle
            list={[
              {
                title: "채용그룹 관리",
                url: `/acghr_hr/${company}/group`,
                current: false,
              },
              {
                title: "화상룸 목록",
                url: `/acghr_hr/${company}/roomList${id}`,
                current: true,
              },
            ]}
          />

          <Box>
            <Stack direction={"row"} gap={1}>
              {companyInfo.companyIdx === 1 ? (
                <>
                  {groupInfo?.offlineGroupYN === "Y" ? (
                    <Button variant="outlined" size="medium" onClick={() => handleOpenModal("markData")} startIcon={<Upload strokeWidth={1.2} size={18} />}>
                      마킹값 업로드
                    </Button>
                  ) : null}

                  <Button
                    variant="outlined"
                    size="medium"
                    onClick={() => handleOpenModal("testSetStatus")}
                    startIcon={<FileSpreadsheet strokeWidth={1.2} size={18} />}
                  >
                    검사 SET 현황
                  </Button>
                  <Button variant="outlined" size="medium" onClick={handleAssignRoom} startIcon={<Component size={17} strokeWidth={1.3} />}>
                    룸 배정
                  </Button>
                  <Button
                    variant="outlined"
                    size={"small"}
                    onClick={handleAddOneRoom}
                    disabled={data && _.find(data?.data, { roomNo: 0 })?.preDone < 1 && data.data?.length < 2 ? true : false}
                    startIcon={<FolderPlus size={17} strokeWidth={1.3} />}
                  >
                    룸 추가
                  </Button>
                  <Button startIcon={<FunctionSquare size={17} strokeWidth={1.3} />} onClick={handleScoring} variant="outlined">
                    결과 처리
                  </Button>
                </>
              ) : null}
              <Button startIcon={<FileDown size={17} strokeWidth={1.3} />} onClick={handleOpenDownloadListMenu} variant="outlined">
                다운로드
              </Button>
              <Menu anchorEl={downloadAnchorEl} open={openDownloadMenu} onClose={handleCloseDownloadListMenu}>
                {companyInfo.companyIdx === 1 ? (
                  <MenuItem
                    onClick={() => handleOpenModal("testerList")}
                    sx={{
                      "&>.MuiListItemIcon-root": {
                        minWidth: 25,
                      },
                    }}
                  >
                    <ListItemIcon>
                      <ClipboardList size={17} />
                    </ListItemIcon>
                    출석부
                  </MenuItem>
                ) : null}
                {companyInfo.companyIdx === 1 ? (
                  <MenuItem
                    sx={{
                      "&>.MuiListItemIcon-root": {
                        minWidth: 25,
                      },
                    }}
                    onClick={handleOpenDownloadMarkData}
                  >
                    <ListItemIcon>
                      <Database size={17} />
                    </ListItemIcon>
                    응답값
                  </MenuItem>
                ) : null}
                <MenuItem
                  sx={{
                    "&>.MuiListItemIcon-root": {
                      minWidth: 25,
                    },
                  }}
                  onClick={() => handleOpenModal("testerStatusList")}
                >
                  <ListItemIcon>
                    <LayoutList size={17} />
                  </ListItemIcon>
                  응시 현황
                </MenuItem>
                {companyInfo.companyIdx === 1 ? (
                  <MenuItem
                    sx={{
                      "&>.MuiListItemIcon-root": {
                        minWidth: 25,
                      },
                    }}
                    onClick={handleDownloadAIdata}
                  >
                    <ListItemIcon>
                      <BrainCircuit size={17} />
                    </ListItemIcon>
                    AI 감지 Data
                  </MenuItem>
                ) : null}
                <MenuItem
                  sx={{
                    "&>.MuiListItemIcon-root": {
                      minWidth: 25,
                    },
                  }}
                  onClick={handleOpenDownloadReportListMenu}
                >
                  <ListItemIcon>
                    <FileText size={17} />
                  </ListItemIcon>
                  레포트
                </MenuItem>
              </Menu>
              {/* 레포트 */}
              <Menu
                anchorEl={reportMenuEl}
                open={openDownloadReportMenu}
                onClose={handleCloseReportDownloadListMenu}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
              >
                <MenuItem
                  sx={{
                    "&>.MuiListItemIcon-root": {
                      minWidth: 25,
                    },
                  }}
                  onClick={() => handleOpenProgressModal("hr")}
                >
                  <ListItemIcon>
                    <Users2 size={17} />
                  </ListItemIcon>
                  그룹
                </MenuItem>
                <MenuItem
                  sx={{
                    "&>.MuiListItemIcon-root": {
                      minWidth: 25,
                    },
                  }}
                  onClick={() => handleOpenProgressModal("personal")}
                >
                  <ListItemIcon>
                    <User2 size={17} />
                  </ListItemIcon>
                  개인
                </MenuItem>
              </Menu>
              {/* 응답값 */}
              <Menu
                anchorEl={downloadMarkDataAnchorEl}
                open={openDownloadMarkDataMenu}
                onClose={handleCloseDownloadMarkData}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
              >
                {companyInfo.companyIdx === 1 ? (
                  <MenuItem
                    sx={{
                      "&>.MuiListItemIcon-root": {
                        minWidth: 25,
                      },
                    }}
                    onClick={(e) => handleDownloadGroupMarkData(_, "MARK")}
                  >
                    <ListItemIcon>
                      <Users2 size={17} />
                    </ListItemIcon>
                    Raw Data
                  </MenuItem>
                ) : null}
                <MenuItem
                  sx={{
                    "&>.MuiListItemIcon-root": {
                      minWidth: 25,
                    },
                  }}
                  onClick={(e) => handleDownloadGroupMarkData(_, "INTERVAL")}
                >
                  <ListItemIcon>
                    <User2 size={17} />
                  </ListItemIcon>
                  풀이시간
                </MenuItem>
              </Menu>
            </Stack>
          </Box>
        </Grid>
        <Grid>
          {groupInfo && (
            <Card
              variant="outlined"
              sx={{
                background: "transparent",
                border: "none",
                "& .MuiCardContent-root:last-child": { padding: 0 },
              }}
            >
              <CardContent>
                <GroupCard selectedItem={groupInfo} status={isLoading || isFetching} />
              </CardContent>
            </Card>
          )}
        </Grid>
        <Grid container alignItems="center" justifyContent="space-between">
          <Box>
            <Grid container alignItems="center">
              <Typography fontSize={"13px"} fontWeight={700}>{`룸 갯수 : 총 ${pageInfo.total.toLocaleString()} 개`}</Typography>
              <Collapse
                unmountOnExit
                orientation="horizontal"
                in={selected.length === 0 || selected.length >= 2 ? false : true}
                sx={{
                  "&": {
                    display: "inline-block",
                    ml: 1,
                  },
                  "& .MuiCollapse-hidden": {
                    height: 0,
                  },
                }}
              >
                <Tooltip TransitionComponent={Fade} title="한번에 1개씩만 삭제 가능합니다." placement="top-start" arrow>
                  <Box component="span">
                    <IconButton
                      disabled={selected.length === 0 || selected.length >= 2 ? true : false}
                      variant="outlined"
                      size="small"
                      sx={{
                        background: "#ffe8e8",
                        borderRadius: "50%",
                      }}
                      onClick={handleDeleteSelectRoom}
                    >
                      <RemoveRoundedIcon color="warning" />
                    </IconButton>
                  </Box>
                </Tooltip>
              </Collapse>
            </Grid>
          </Box>
          <Box>
            <Grid item>
              <Grid container columnGap={1}>
                {companyInfo.companyIdx === 1 ? (
                  <>
                    <Grid item>
                      <Button
                        variant="contained"
                        onClick={() => handleOpenModal("addTester")}
                        sx={{
                          "&>span>svg": { width: 20, height: 20 },
                        }}
                        disableElevation
                        startIcon={<AddTester />}
                      >
                        응시자 등록/추가
                      </Button>
                    </Grid>

                    <Grid item>
                      <Button
                        variant="outlined"
                        onClick={() => handleOpenModal("addManager")}
                        sx={{ "&>span>svg": { width: 20, height: 20 } }}
                        startIcon={<Video size={17} strokeWidth={1.3} />}
                      >
                        감독관
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button variant="outlined" onClick={() => handleOpenModal("sendMessage")} startIcon={<Mail size={17} strokeWidth={1.3} />}>
                        메세지
                      </Button>
                    </Grid>
                  </>
                ) : null}
              </Grid>
            </Grid>
          </Box>
        </Grid>
        {isFetching || isLoading ? (
          <LoadingSkeleton />
        ) : (
          <>
            <Paper
              sx={{
                ...tableWrapper,
                overflowY: "hidden",
                height: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "space-between",
              }}
              variant="outlined"
            >
              {data && data.data?.length < 1 ? (
                <Box height={"100%"} display="flex" alignItems={"center"} justifyContent="center" py={10}>
                  <EmptyPlaceholder />
                </Box>
              ) : (
                <>
                  <TableContainer sx={{ height: "calc(93% - 20px)" }}>
                    <Table stickyHeader sx={tableHeader} size="small">
                      <TableHead>
                        <TableRow>
                          <TableCell padding="checkbox" align="center">
                            <Checkbox size="small" color="primary" onChange={handleSelectAllClick} />
                          </TableCell>
                          {GROUP_ROOM_LIST.map((item) => (
                            <TableCell key={item.label} sx={{ width: item.size, fontWeight: "bold" }} align="center">
                              {item.label}
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {data &&
                          data.data?.map((row) => (
                            <TableRow
                              hover
                              key={row.roomIdx}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                                cursor: "pointer",
                              }}
                              tabIndex={-1}
                            >
                              <TableCell padding="none" align="center" onClick={(e) => handleClickRow(e, row)}>
                                <Checkbox size="small" color="primary" onClick={(event) => handleCheckClick(event, row)} checked={isSelected(row.roomIdx)} />
                              </TableCell>
                              <TableCell padding="none" align="center" scope="row" onClick={(e) => handleClickRow(e, row)}>
                                {row.roomNo === 0 ? "대기" : row.roomNo}
                              </TableCell>
                              <TableCell padding="none" align="center" onClick={(e) => handleClickRow(e, row)}>
                                {companyInfo.companyIdx === 1 ? row.managerName : maskingName(row.managerName)}
                              </TableCell>
                              <TableCell padding="none" align="center" onClick={(e) => handleClickRow(e, row)}>
                                {companyInfo.companyIdx === 1 ? row.managerCell : maskingPhoneNumber(row.managerCell)}
                              </TableCell>
                              <TableCell
                                padding="none"
                                align="center"
                                onClick={(e) => (row.authKey === null ? handleClickRow(e, row) : handleCopyClipBoard(row.authKey))}
                              >
                                <Grid
                                  container
                                  alignItems={"center"}
                                  justifyContent="center"
                                  sx={{
                                    "&:hover": {
                                      textDecoration: "underline",
                                    },
                                  }}
                                >
                                  <Typography>{row.authKey}</Typography>
                                  {row.roomNo !== 0 && <ContentCopyRoundedIcon fontSize="small" sx={{ color: "#777", ml: 1 }} />}
                                </Grid>
                              </TableCell>
                              <TableCell padding="none" align="center" onClick={(e) => handleClickRow(e, row)}>
                                {row.total}
                              </TableCell>
                              <TableCell padding="none" align="center" onClick={(e) => handleClickRow(e, row)}>
                                {row.preDone}
                              </TableCell>
                              <TableCell padding="none" align="center" onClick={(e) => handleClickRow(e, row)}>
                                {row.done}
                              </TableCell>
                              <TableCell padding="none" align="center" onClick={(e) => handleClickRow(e, row)}>
                                {row.inProgress}
                              </TableCell>
                              <TableCell padding="none" align="center" onClick={(e) => handleClickRow(e, row)}>
                                {row.notDone}
                              </TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <Grid container alignItems={"center"} justifyContent="center" my={1}>
                    <Pagination
                      size="small"
                      count={pageInfo.totalPage}
                      page={pageInfo.pageNo}
                      onChange={(_, page) => {
                        setParamValues((prev) => ({ ...prev, pageNo: page }));
                      }}
                    />
                  </Grid>
                </>
              )}
            </Paper>
          </>
        )}
      </Box>
      <Dialog open={state.modal === "testerList"} onClose={handleCloseModal} fullWidth maxWidth="xs">
        <Suspense fallback={<LoadingCircle />}>
          <DownloadTesterListModal groupIdx={id} roomIdx={selected} onClose={handleCloseModal} />
        </Suspense>
      </Dialog>

      {/* 응시자 등록 모달 */}
      <Dialog open={state.modal === "addTester"} onClose={handleCloseModal} fullWidth maxWidth="sm">
        <Suspense fallback={<LoadingCircle />}>
          <CreateTesterModal groupIdx={id} roomIdx={selected} onClose={handleCloseModal} />
        </Suspense>
      </Dialog>
      {/* 감독관 등록 모달 */}
      <Dialog open={state.modal === "addManager"} onClose={handleCloseModal} fullWidth maxWidth="xs">
        <Suspense fallback={<LoadingCircle />}>
          <CreateManagerModal groupIdx={id} roomIdx={selected} setSelected={setSelected} onClose={handleCloseModal} />
        </Suspense>
      </Dialog>
      {/* 메세지 전송 모달 */}
      <Dialog open={state.modal === "sendMessage"} onClose={handleCloseModal} fullWidth maxWidth="xs">
        <Suspense fallback={<LoadingCircle />}>
          <SendMessageModal rooms={data} groupIdx={id} roomIdx={selected} onClose={handleCloseModal} />
        </Suspense>
      </Dialog>
      {/* 그룹 마킹값 다운로드 */}
      <Dialog open={state.modal === "MARK" || state.modal === "INTERVAL"} onClose={handleCloseModal} fullWidth maxWidth="xs">
        <Suspense fallback={<LoadingCircle />}>
          <DownloadGroupMarkDataModal groupIdx={id} onClose={handleCloseModal} markType={markType} />
        </Suspense>
      </Dialog>
      {/* 응시자 현황 다운로드 */}
      <Dialog open={state.modal === "testerStatusList"} onClose={handleCloseModal} fullWidth maxWidth="xs">
        <Suspense fallback={<LoadingCircle />}>
          <TesterStatusListDownloadModal groupIdx={id} onClose={handleCloseModal} />
        </Suspense>
      </Dialog>
      {/* Progress Modal */}
      <Dialog open={progressModalState.modal === "hr"} fullWidth maxWidth="xs">
        <Suspense fallback={<LoadingCircle />}>
          <DownloadProgressModalMemoized mutateId="groupHRreport" queryId="reportDownloadHR" onClose={handleCloseProgressModal} id={Number(id)} />
        </Suspense>
      </Dialog>
      {/* Progress Modal */}
      <Dialog open={progressModalState.modal === "personal"} fullWidth maxWidth="xs">
        <Suspense fallback={<LoadingCircle />}>
          <DownloadProgressModalMemoized mutateId="personalHRreport" queryId="reportDownloadPersonal" onClose={handleCloseProgressModal} id={Number(id)} />
        </Suspense>
      </Dialog>
      {/* Progress Modal */}
      <Dialog open={progressModalState.modal === "interviewer"} fullWidth maxWidth="xs">
        <Suspense fallback={<LoadingCircle />}>
          <DownloadProgressModalMemoized mutateId="interviewerReport" queryId="reportDownloadInterviewer" onClose={handleCloseProgressModal} id={Number(id)} />
        </Suspense>
      </Dialog>

      <Dialog
        open={progressModalState.modal === "scoreTest"}
        // onClose={handleCloseProgressModal}
        fullWidth
        maxWidth="xs"
      >
        <Suspense fallback={<LoadingCircle />}>
          <DownloadScoreTestModal id={Number(id)} onClose={handleCloseProgressModal} />
        </Suspense>
      </Dialog>
      {/* Progress Modal */}
      <Dialog open={progressModalState.modal === "customized"} fullWidth maxWidth="xs">
        <Suspense fallback={<LoadingCircle />}>
          <DownloadProgressModalMemoized mutateId="customizedReport" queryId="reportDownloadCustomized" onClose={handleCloseProgressModal} id={Number(id)} />
        </Suspense>
      </Dialog>

      {/* 검사세트 현황 */}
      <Dialog open={state.modal === "testSetStatus"} onClose={handleCloseModal} fullWidth maxWidth="xs">
        <Suspense fallback={<LoadingCircle />}>
          <TestSetStatus onClose={handleCloseModal} />
        </Suspense>
      </Dialog>

      <Dialog open={state.modal === "markData"} onClose={handleCloseModal} fullWidth maxWidth="xs">
        <Suspense fallback={<LoadingCircle />}>
          <UploadMarkDataModal onClose={handleCloseModal} />
        </Suspense>
      </Dialog>
      <Snackbar anchorOrigin={{ vertical: "top", horizontal: "center" }} open={copyResult} autoHideDuration={1000} onClose={handleCloseAlert}>
        <Alert onClose={handleCloseAlert} severity={isCopyError ? "error" : "success"} sx={{ width: "100%" }}>
          <Typography fontWeight={700}>{copyResultMessage}</Typography>
        </Alert>
      </Snackbar>
    </>
  );
}
