import React, { Suspense, useState } from "react";

// Style
import ErrorOutlineRoundedIcon from "@mui/icons-material/ErrorOutlineRounded";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Pagination from "@mui/material/Pagination";
import Paper from "@mui/material/Paper";
import Snackbar from "@mui/material/Snackbar";
import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";

// Components
import TopTitle from "components/templates/topTitle/TopTitle";
import EmptyPlaceholder from "components/UI/organism/EmptyPlaceholder";
import LoadingSkeleton from "components/UI/organism/LoadingSkeleton";
import UpdateRoomTesterModal from "../group/main/roomList/testerMenu/UpdateRoomTesterModal";
import SearchTestersInput from "./SearchTestersInput";

// Router
import { useNavigate, useParams } from "react-router-dom";

// Recoil
import { useRecoilState, useSetRecoilState } from "recoil";
import { groupInfoAtom, searchTesterParams } from "recoil/store";

// React-query
import useGetQueryhr from "hooks/useGetQueryhr";

// Enum
import { SEARCH_USER_GUEST } from "enums/tableColumn";
import { tableHeader, tableWrapper } from "themes/theme";

// Utils
import useCopyClipboard from "hooks/useCopyClipboard";
import { bizNo } from "utils/bizNo";
import { dateIncludeTime } from "utils/formatter/dateFormat";
import { YNColor } from "utils/formatter/YNColor";
import { examStatusTag } from "utils/tagRender";

const SearchTesters = () => {
  const { company } = useParams();
  const groupInfo = useSetRecoilState(groupInfoAtom);
  const navigate = useNavigate();

  const [paramValues, setParamValues] = useRecoilState(searchTesterParams);

  const [editTesterModal, setEditTesterModal] = useState(false);

  const { data, isLoading, isFetching } = useGetQueryhr("getTesters", paramValues, true);

  // const setTesterName = useSetRecoilState(searchTesterValue);

  const [message] = useState("검색할 응시자 정보를 입력해 주세요.");

  const handleMove = (row) => {
    groupInfo(row);
    if (row.roomUseYN === "Y") {
      navigate(`/acghr_hr/${company}/roomList/testerList${row.roomIdx}`, {
        state: {
          ...row,
        },
      });
    } else {
      navigate(`/acghr_hr/${company}/testerList${row.groupIdx}`, {
        state: {
          ...row,
        },
      });
    }
  };

  //* 행 클립보드 복사
  const { copyResultMessage, copyResult, handleCopyClipBoard, handleCloseAlert, isCopyError } = useCopyClipboard("searchTesters");

  const [testerInfo, setTesterInfo] = useState({});

  const handleEditTesterModal = (_, row) => {
    setEditTesterModal((prev) => !prev);
    setTesterInfo(row);
  };

  const handlePage = (_, page) => setParamValues((prev) => ({ ...prev, pageNo: page }));

  return (
    <>
      <Box width="100%" height="100%" borderRadius={2} display="flex" flexDirection="column" rowGap={2}>
        <Grid container justifyContent={"space-between"} mb={1}>
          <TopTitle
            list={[
              {
                title: "응시자 검색",
                url: `/acghr_hr/${company}/searchTester`,
                current: true,
              },
            ]}
          />
        </Grid>

        {isFetching ? (
          <LoadingSkeleton />
        ) : (
          <Paper
            sx={{
              ...tableWrapper,
              overflowY: "hidden",
              height: "100%",
              display: "flex",
              flexDirection: "column",
              borderRadius: 2,
              p: 2,
              rowGap: 2,
            }}
            variant="outlined"
          >
            <SearchTestersInput />
            <TableContainer sx={{ height: "calc(95% - 20px)" }}>
              <Table stickyHeader sx={tableHeader} size="small">
                <TableHead>
                  <TableRow>
                    {SEARCH_USER_GUEST.map((item) => {
                      return item.flag === false ? null : (
                        <TableCell padding="none" key={item.label} sx={{ width: item.size, fontWeight: "bold" }} align="center">
                          {item.label}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {!paramValues.isSearched ? (
                    <TableRow
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell colSpan={13} height={300}>
                        <EmptyPlaceholder message={message} />
                      </TableCell>
                    </TableRow>
                  ) : (
                    data &&
                    data.data.map((row, index) => (
                      <React.Fragment key={index}>
                        <TableRow
                          hover
                          key={row.userIdx}
                          sx={{
                            cursor: "pointer",
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                          role="checkbox"
                          tabIndex={-1}
                        >
                          {/* 회사이름 */}
                          <TableCell align="center" padding="none">
                            <Button onClick={() => handleCopyClipBoard(row)} size="small" variant="outlined" sx={{ "&": { maxWidth: 40, minWidth: 40 } }}>
                              복사
                            </Button>
                          </TableCell>

                          <TableCell align="center" onClick={() => handleMove(row)}>
                            <Typography
                              fontWeight={600}
                              fontSize={10.5}
                              sx={{
                                "&:hover": {
                                  textDecoration: "underline",
                                },
                              }}
                            >
                              {row.groupName}
                            </Typography>
                          </TableCell>

                          <TableCell align="center">{dateIncludeTime(new Date(row.examSAt))}</TableCell>
                          <TableCell align="center">{dateIncludeTime(new Date(row.examSAt))}</TableCell>

                          <TableCell align="center">{row.companyName}</TableCell>

                          <TableCell align="center">{row.testerName}</TableCell>

                          <TableCell align="center">{row.testerBirth}</TableCell>
                          <TableCell align="center">{row.testerCell}</TableCell>
                          <TableCell align="center">
                            <Tooltip arrow title={row.normName} placement="top-end">
                              <Grid width={90} mx="auto">
                                <Typography noWrap variant="body2">
                                  {row.normName}
                                </Typography>
                              </Grid>
                            </Tooltip>
                          </TableCell>
                          <TableCell align="center">
                            <Typography noWrap variant="body2">
                              {bizNo(row.bizNo)}
                            </Typography>
                          </TableCell>

                          <TableCell align="center" position={"relative"}>
                            <Stack direction={"row"} columnGap={1} justifyContent="center">
                              <Box position={"relative"}>
                                {examStatusTag(row.examStatus)}
                                {row.giveUpYN === "Y" && (
                                  <Box position={"absolute"} top={2.5} right={-20}>
                                    <Tooltip title="응시포기" arrow placement="top">
                                      <ErrorOutlineRoundedIcon color="error" />
                                    </Tooltip>
                                  </Box>
                                )}
                              </Box>
                            </Stack>
                          </TableCell>

                          <TableCell align="center">{YNColor(row.foreignLoginYN)}</TableCell>

                          <TableCell align="center" padding="none">
                            <IconButton onClick={(e) => handleEditTesterModal(e, row)}>
                              <SettingsOutlinedIcon />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      </React.Fragment>
                    ))
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <Grid container alignItems={"center"} justifyContent="center">
              <Pagination size="small" count={data?.totalPage} page={paramValues?.pageNo} onChange={(_, page) => handlePage(_, page)} />
            </Grid>
          </Paper>
        )}
        <Snackbar anchorOrigin={{ vertical: "top", horizontal: "center" }} open={copyResult} autoHideDuration={1000} onClose={handleCloseAlert}>
          <Alert onClose={handleCloseAlert} severity={isCopyError ? "error" : "success"} sx={{ width: "100%" }}>
            <Typography fontWeight={700}>{copyResultMessage}</Typography>
          </Alert>
        </Snackbar>
      </Box>
      <Dialog open={editTesterModal} onClose={handleEditTesterModal} fullWidth maxWidth="md">
        <Suspense fallback={<LoadingSkeleton />}>
          <UpdateRoomTesterModal
            queryKey="getTesters"
            // roomTesterInfo={roomTesterInfo}
            roomTesterInfo={testerInfo}
            onClose={handleEditTesterModal}
          />
        </Suspense>
      </Dialog>
    </>
  );
};

export default SearchTesters;
