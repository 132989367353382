import React from "react";

import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";

import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/airbnb.css";
import { Korean } from "flatpickr/dist/l10n/ko.js";
import { QnA_CATEGORY, QnA_IS_ANSWERED, QnA_LIST_SEARCH } from "enums/tableColumn";
import { useEffect } from "react";
import { Search } from "lucide-react";
import { dashDelimiter } from "utils/formatter/dateFormat";

const SearchQnaList = ({
  setOptionValue,
  optionValue,
  searchRef,
  submitSearch,
  filterCondition,
  setFilterCondition,
}) => {
  const handleOption = (e) => setOptionValue(e.target.value);

  const handleFilterCondition = (e) => {
    const { name, value } = e.target;
    setFilterCondition((prev) => ({ ...prev, [name]: value }));
  };

  useEffect(() => {
    searchRef.current.focus();
  }, []);

  return (
    <Grid container columnGap={1} alignItems="stretch">
      <TextField
        select
        label="유형"
        size="small"
        name="category"
        sx={{ minWidth: 120 }}
        onChange={handleFilterCondition}
        value={filterCondition?.category}
        children={QnA_CATEGORY.map((item, index) => {
          return (
            <MenuItem key={index} value={item.value}>
              {item.label}
            </MenuItem>
          );
        })}
      />

      <TextField
        select
        label="답변 작성"
        size="small"
        sx={{ minWidth: 120 }}
        name="replySuccessYN"
        onChange={handleFilterCondition}
        value={filterCondition?.replySuccessYN}
        children={QnA_IS_ANSWERED.map((item, index) => {
          return (
            <MenuItem key={index} value={item.value}>
              {item.label}
            </MenuItem>
          );
        })}
      />

      <Flatpickr
        className={"datepickerInput"}
        value={[filterCondition?.sDate, filterCondition?.eDate]}
        placeholder="기간을 선택해주세요."
        options={{
          locale: Korean,
          mode: "range",
        }}
        onChange={([dateFrom, dateTo]) => {
          if (dateFrom?.getTime() === dateTo?.getTime()) {
            let days = 1 * 24 * 60 * 60 * 1000;
            dateTo.setTime(dateTo.getTime() + days);

            setFilterCondition((prev) => ({ ...prev, sDate: dashDelimiter(dateFrom) })); //YYYY-MM-DD
            setFilterCondition((prev) => ({ ...prev, eDate: dashDelimiter(dateTo) }));
          } else {
            console.log(dateFrom, dateTo);
            setFilterCondition((prev) => ({ ...prev, sDate: dashDelimiter(dateFrom) }));
            setFilterCondition((prev) => ({ ...prev, eDate: dashDelimiter(dateTo) }));
          }
        }}
      />

      <TextField
        select
        label="검색내용"
        size="small"
        name="search"
        value={optionValue}
        variant="outlined"
        onChange={handleOption}
        sx={{ minWidth: 140 }}
        children={QnA_LIST_SEARCH.map((item) => (
          <MenuItem key={item.value} value={item.value}>
            {item.label}
          </MenuItem>
        ))}
      />
      <TextField
        size="small"
        inputRef={searchRef}
        sx={{ width: 300 }}
        onKeyUp={(e) => submitSearch(e, "enter")}
        placeholder={"검색내용을 입력해 주세요."}
      />
      <Button variant="outlined" startIcon={<Search size={15} />} onClick={(e) => submitSearch(e, "click")}>
        검색
      </Button>
    </Grid>
  );
};

export default SearchQnaList;
