import { Chip } from "@mui/material";
import React, { useEffect, useState } from "react";

export const FilterListBadge = ({ paramValues, setParamValues }) => {
  const [badgeList, setBadgeList] = useState([]);

  const deleteBadge = (name) => {
    setParamValues((prev) => {
      const newState = { ...prev };
      delete newState[name];
      return newState;
    });
  };
  useEffect(() => {
    const temp = Object.keys(paramValues);
    setBadgeList(
      temp.filter((key) => {
        if (key === "offlineGroupYN") {
          return { offlineGroupYN: "Y" };
        } else if (key === "testGroupYN") {
          return { testGroupYN: "Y" };
        }
      })
    );
  }, [paramValues]);

  const categorize = (item, index) => {
    if (item === "offlineGroupYN") {
      return (
        <Chip
          key={index}
          sx={{
            fontWeight: 700,
            color: "#777",
            background: "transparent",
            border: "1px solid #777",
          }}
          label="오프라인"
          size="small"
          onDelete={() => deleteBadge("offlineGroupYN")}
        />
      );
    } else {
      return (
        <Chip
          key={index}
          sx={{
            background: "#777",
            fontWeight: 700,
            color: "#FFF",
            "&>.MuiChip-deleteIcon": { color: "#FFF" },
          }}
          label="테스트"
          size="small"
          onDelete={() => deleteBadge("testGroupYN")}
        />
      );
    }
  };
  return <>{badgeList.map((item, index) => categorize(item, index))}</>;
};
