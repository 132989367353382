import { lazy, Suspense, useEffect, useRef, useState } from "react";

// Style
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import Badge from "@mui/material/Badge";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import Collapse from "@mui/material/Collapse";
import Dialog from "@mui/material/Dialog";
import Fade from "@mui/material/Fade";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import Pagination from "@mui/material/Pagination";
import Paper from "@mui/material/Paper";
import Select from "@mui/material/Select";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import Skeleton from "@mui/material/Skeleton";
import TableContainer from "@mui/material/TableContainer";
import { Trash2, UndoDot } from "lucide-react";

// Recoil
import { useRecoilState } from "recoil";
import { perPageAtom } from "recoil/store";

// Data Fetching
import useGetQueryhr from "hooks/useGetQueryhr";

// ENUM
import { QnA_TABLE_ROW } from "enums/tableColumn";
import { tableHeader, tableWrapper } from "themes/theme";

// Component
import TopTitle from "components/templates/topTitle/TopTitle";
import LoadingSkeleton from "components/UI/organism/LoadingSkeleton";
import SearchQnaList from "./read/SearchQnaList";

// Router
import { useParams } from "react-router-dom";

// Utils
import { calcRowNum } from "utils/calcRowNum";
import { checkboxSelect } from "utils/checkboxSelect";
import { dateIncludeTimeQnA, todayFlag } from "utils/formatter/dateFormat";
import { qnaCategory } from "utils/qnaCategory";
import Swal from "sweetalert2";
import { alertModalConfig } from "components/templates/modalConfig";
import { warningIcon } from "assets/icon/Icon_modal";

const RemovedListQnAModal = lazy(() => import("./RemovedListQnAModal"));
const DeleteQnAModal = lazy(() => import("./delete/DeleteQnAModal"));
const QnaReplyModal = lazy(() => import("./QnaReplyModal"));
const QnAListReply = lazy(() => import("./QnAListReply"));

export default function QnAList() {
  const { company } = useParams();
  const [replyModalOn, setReplyModalOn] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});
  const [optionValue, setOptionValue] = useState("companyName");
  const [filterCondition, setFilterCondition] = useState({
    category: "",
    replySuccessYN: "",
    sDate: "",
    eDate: "",
  });
  const searchRef = useRef("");
  const [deleteModal, setDeleteModal] = useState(false);
  const [trashModal, setTrashModal] = useState(false);
  const [selected, setSelected] = useState([]);
  const [paramValues, setParamValues] = useState({
    pageNo: 1,
  });
  const [perPage, setPerPage] = useRecoilState(perPageAtom);
  const { data, pageInfo, isLoading, isFetching } = useGetQueryhr("totalQna", paramValues);

  const handleReplyModal = (row) => {
    setSelectedRow(row);
    setReplyModalOn(!replyModalOn);
  };

  const handleDeleteModal = () => setDeleteModal(!deleteModal);
  const handleTrashModal = () => setTrashModal(!trashModal);

  const submitSearch = async (e, name) => {
    let searchContent = {
      [optionValue]: searchRef.current.value,
    };

    let submit = { ...searchContent, ...filterCondition };

    if (submit.eDate.includes("NaN")) {
      Swal.fire(
        alertModalConfig({
          title: "Q&A 검색",
          text: "일자 선택 범위를 다시 확인해 주세요.",
          iconHtml: warningIcon,
          confirmButtonText: "확인",
        })
      );
      return;
    }

    // 필터만 적용했을 경우
    for (const key in submit) {
      if (key === "") delete submit[""];
    }

    // 필터 미적용 항목 삭제
    for (const key in submit) {
      if (submit[key] === "") delete submit[key];
    }

    if (e.key === "Enter") {
      setParamValues({ pageNo: 1, ...submit });
    } else {
      if (name === "click") {
        setParamValues({ pageNo: 1, ...submit });
      }
    }
  };

  const handleClick = (_, name) => {
    const { qnaIdx } = name;
    const selectedIndex = selected.indexOf(qnaIdx);
    setSelected(checkboxSelect(selected, selectedIndex, qnaIdx));
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = data.qnaList?.map((n) => n.qnaIdx);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handlePerPage = (e) => {
    setPerPage(e.target.value);
    setParamValues((prev) => ({
      ...prev,
      perPage: e.target.value,
    }));
  };

  useEffect(() => {
    setPerPage(20);
  }, []);

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const handleDeleteQnA = () => setDeleteModal(true);
  const handleTrash = () => setTrashModal(true);

  const isMobile = useMediaQuery("(max-width:500px)");

  function Row(props) {
    const { row } = props;
    const [rowOpen, setRowOpen] = useState(false);

    const handleClickRow = (e, row, cellIndex) => {
      if (e.target.cellIndex >= 1 || cellIndex === 2) setRowOpen(!rowOpen);
    };

    return (
      <>
        <TableRow
          sx={{
            cursor: row.replySuccessYN === "Y" && "pointer",
            "& > td": { borderBottom: "none" },
          }}
          onClick={(e) => handleClickRow(e, row)}
          hover
        >
          <TableCell align="center" padding="none">
            <Checkbox
              size="small"
              color="primary"
              onClick={(event) => handleClick(event, row)}
              checked={isSelected(row?.qnaIdx)}
            />
          </TableCell>
          <TableCell align="center" padding="none">
            {calcRowNum(props.index, pageInfo, perPage)}
          </TableCell>
          <TableCell align="center" padding="none">
            {row?.companyName}
          </TableCell>
          <TableCell align="center" padding="none">
            {qnaCategory(row?.category)}
          </TableCell>

          <TableCell
            padding="none"
            align="center"
            sx={{
              maxWidth: 250,
            }}
          >
            <Grid
              container
              alignItems={"center"}
              justifyContent="center"
              position="relative"
              onClick={(e) => handleClickRow(e, row, 2)}
            >
              <Typography variant="body2" noWrap maxWidth={250}>
                {row.text}
              </Typography>
              {todayFlag(row.createdAt) ? (
                <Badge
                  sx={{
                    "& .MuiBadge-badge": {
                      color: "white",
                      height: "13px",
                      zIndex: 0,
                    },
                    position: "absolute",
                    right: 10,
                  }}
                  badgeContent={"N"}
                  color="badge"
                />
              ) : null}
            </Grid>
          </TableCell>
          <TableCell align="center"> {row?.testerEmail}</TableCell>
          <TableCell align="center"> {row?.testerName}</TableCell>
          <TableCell align="center"> {row?.testerCell || "-"}</TableCell>
          <TableCell align="center"> {row?.adminName || "-"}</TableCell>
          <TableCell align="center">{dateIncludeTimeQnA(new Date(row?.createdAt))}</TableCell>
          <TableCell align="center" padding="none">
            {row.replySuccessYN === "N" ? (
              <Button size="small" variant={"outlined"} onClick={() => handleReplyModal(row)}>
                미완료
              </Button>
            ) : (
              <CheckRoundedIcon color="success" />
            )}
          </TableCell>
        </TableRow>
        <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={11}>
            <Collapse in={rowOpen} timeout="auto" unmountOnExit>
              <Suspense fallback={<Skeleton variant="rounded" height={20} sx={{ my: 1 }} />}>
                <QnAListReply replyText={row.replyText} adminName={row.adminName} text={row.text} />
              </Suspense>
            </Collapse>
          </TableCell>
        </TableRow>
      </>
    );
  }

  return (
    <>
      <Box
        sx={{
          width: "100%",
          height: "100%",
          borderRadius: 2,
        }}
        display="flex"
        flexDirection="column"
        rowGap={2}
      >
        <Grid container justifyContent="space-between" mb={1}>
          <TopTitle
            list={[
              {
                title: "응시자 Q&A",
                url: `/acghr_hr/${company}/qna`,
                current: true,
              },
            ]}
          />
        </Grid>

        {isLoading || isFetching ? (
          <LoadingSkeleton />
        ) : (
          <>
            <Paper
              sx={{
                ...tableWrapper,
                overflowY: "hidden",
                height: "100%",
                display: "flex",
                flexDirection: "column",
                borderRadius: 2,
                p: 2,
                rowGap: 2,
              }}
              variant="outlined"
            >
              <SearchQnaList
                setOptionValue={setOptionValue}
                optionValue={optionValue}
                submitSearch={submitSearch}
                searchRef={searchRef}
                filterCondition={filterCondition}
                setFilterCondition={setFilterCondition}
              />
              <Grid justifyContent="space-between" container alignItems={"center"}>
                <Box>
                  <Grid item sm={12} container columnGap={2} alignItems="center">
                    <Select size="small" defaultValue={perPage} onChange={handlePerPage}>
                      <MenuItem key={10} value={10}>
                        10/Page
                      </MenuItem>
                      <MenuItem key={20} value={20}>
                        20/Page
                      </MenuItem>
                      <MenuItem key={50} value={50}>
                        50/Page
                      </MenuItem>
                    </Select>
                  </Grid>
                </Box>
                <Box>
                  <Grid item container sm={12} columnGap={1}>
                    <Button
                      variant="outlined"
                      disableElevation
                      startIcon={<UndoDot size={15} />}
                      sx={{
                        "&": {
                          color: "#08b41a",
                        },
                      }}
                      onClick={handleTrash}
                    >
                      복원
                    </Button>

                    <Tooltip
                      TransitionComponent={Fade}
                      title="한번에 1개씩만 삭제 가능합니다."
                      placement="top-start"
                      arrow
                    >
                      <Badge>
                        <Button
                          startIcon={<Trash2 size={15} />}
                          variant="contained"
                          color="removeButton"
                          disableElevation
                          disabled={selected.length === 0 || selected.length >= 2 ? true : false}
                          onClick={handleDeleteQnA}
                          sx={{ "&": { color: "#c42c08" } }}
                        >
                          선택항목 삭제
                        </Button>
                      </Badge>
                    </Tooltip>
                  </Grid>
                </Box>
              </Grid>
              <TableContainer>
                <Table stickyHeader sx={tableHeader} size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell padding="none" align="center" sx={{ width: 20 }}>
                        <Checkbox size="small" style={{ color: "#637381" }} onChange={handleSelectAllClick} />
                      </TableCell>
                      {QnA_TABLE_ROW.map((item) => {
                        return (
                          <TableCell
                            width={item.size}
                            padding="none"
                            key={item.label}
                            sx={{
                              fontWeight: "bold",
                            }}
                            align="center"
                          >
                            {item.label}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data && data.qnaList.map((row, index) => <Row index={index} key={row.qnaIdx} row={row} />)}
                  </TableBody>
                </Table>
              </TableContainer>
              <Grid container alignItems={"center"} justifyContent="center">
                <Pagination
                  size="small"
                  count={pageInfo.totalPage}
                  page={pageInfo.pageNo}
                  onChange={(_, page) => {
                    setParamValues((prev) => ({
                      ...prev,
                      pageNo: page,
                    }));
                  }}
                />
              </Grid>
            </Paper>
          </>
        )}
      </Box>
      {/* Q&A딥변 */}
      <Dialog open={replyModalOn} onClose={handleReplyModal} maxWidth="sm" fullWidth>
        <Suspense fallback={<LoadingSkeleton />}>
          <QnaReplyModal detail={selectedRow} onClose={handleReplyModal} />
        </Suspense>
      </Dialog>{" "}
      {/* Q&A삭제 */}
      <Dialog open={deleteModal} onClose={handleDeleteModal} maxWidth="xs" fullWidth>
        <Suspense fallback={<LoadingSkeleton />}>
          <DeleteQnAModal detail={selected} onClose={handleDeleteModal} setSelected={setSelected} />
        </Suspense>
      </Dialog>
      {/* 삭제된 Q&A */}
      <Dialog open={trashModal} onClose={handleTrashModal} maxWidth="xs" fullWidth>
        <Suspense fallback={<LoadingSkeleton />}>
          <RemovedListQnAModal detail={selected} onClose={handleTrashModal} />
        </Suspense>
      </Dialog>
    </>
  );
}
