import { Chip } from "@mui/material";
import ExamMapType from "components/UI/ExamMapType";
import GroupStatusTag from "components/UI/GroupStatusTag";

export const tagRender = (input) => <GroupStatusTag status={input} />;

export const examMAPtype = (input) => <ExamMapType type={input} />;

export const examStatusTagOnlyText = (input) => {
  switch (input) {
    case 0:
      return "미실시";
    case 1:
      return "진행중";
    case 2:
      return "검사완료";
    default:
      return "검사예정";
  }
};

export const examStatusTag = (input) => {
  switch (input) {
    case "미실시":
    case 0:
      return (
        <Chip
          label={"미실시"}
          sx={{
            fontWeight: 700,
            px: 0.5,
            "& > span": {
              fontSize: "0.9rem",
              color: "#91919e",
            },
          }}
          size="small"
        />
      );

    case "진행중":
    case 1:
      return (
        <Chip
          label={"진행중"}
          sx={{
            fontWeight: 700,
            background: "#6563FF",
            px: 0.5,
            "& > span": {
              fontSize: "0.9rem",
              color: "white",
            },
          }}
          size="small"
        />
      );

    case "검사완료":
    case 2:
      return (
        <Chip
          label={"검사완료"}
          sx={{
            fontWeight: 700,
            background: "#777777",
            px: 0.5,
            "& > span": {
              fontSize: "0.9rem",
              color: "white",
            },
          }}
          size="small"
        />
      );

    default:
      return (
        <Chip
          variant="outlined"
          label={"검사예정"}
          sx={{
            fontWeight: 700,
            borderColor: "#6563FF",
            "& > span": {
              fontSize: "0.9rem",
              color: "#6563FF",
            },
          }}
          size="small"
        />
      );
  }
};
