import React, {
  useRef,
  useEffect,
  useState,
  Suspense,
  lazy,
  useLayoutEffect,
} from "react";

import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Dialog from "@mui/material/Dialog";

import LoadingSkeleton from "components/UI/organism/LoadingSkeleton";

import { useNavigate } from "react-router-dom";

import { useSetRecoilState } from "recoil";
import { HRcompanyInfo } from "recoil/store";

import useMutationHook from "hooks/useMutationHook";

import { Controller, useForm } from "react-hook-form";

// import LOGO from "assets/image/logo/ACG_CI-그레이2.png";
import LOGO from "assets/image/logo/SK_LOGO.png";
import { jwtDecoder } from "utils/jwtDecoder";
import { REQUIRE_VALUE } from "enums/errorMessage";
import Swal from "sweetalert2";
import { alertModalConfig } from "components/templates/modalConfig";
import { checkIcon, errorIcon, questionIcon } from "assets/icon/Icon_modal";
import { LogIn, RotateCw } from "lucide-react";
import { useTimer } from "hooks/useTimer";
import { motion } from "framer-motion";
import { addZero } from "utils/formatter/dateFormat";

const ResetPasswordModal = lazy(() =>
  import("./passwordModal/ResetPasswordModal")
);

export default function LoginPage_hr() {
  const {
    control,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues: {
      userId: "",
      userPassword: "",
    },
  });

  const authKeyRef = useRef("");
  const psRef = useRef(null);
  const navigate = useNavigate();

  const companyInfoAtom = useSetRecoilState(HRcompanyInfo);

  const [showPassword, setShowPassword] = useState(false);
  const [timerStart, setTimerStart] = useState(false);
  const [login2fa, setLogin2fa] = useState(false);
  const [resetPasswordModalOn, setResetPasswordModalOn] = useState(false);
  const [startTime, setStartTime] = useState(180);
  const { count, setCount } = useTimer(timerStart, startTime);
  const [userIdx, setUserIdx] = useState(undefined);
  const authKey = useRef();
  //* 인증번호 요청
  const { mutate } = useMutationHook("loginHR", "HR");

  //* 인증번호 입력 후 로그인
  const { mutate: login } = useMutationHook("login", "HR");

  useEffect(() => {
    // removeCookie("ACG");
    sessionStorage.clear();
  }, []);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleSuccessLogin = (res) => {
    const userInfo = jwtDecoder(res.data.data.accessToken);
    sessionStorage.setItem(userInfo.companyCode, res.data.data.accessToken);
    companyInfoAtom(userInfo);

    if (userInfo.userGradeIdx === 3)
      return navigate(`/acghr_hr/${userInfo.companyCode}/stats`);
    else return navigate(`/acghr_hr/${userInfo.companyCode}/group`);
  };

  const handleResetPasswordModal = () =>
    setResetPasswordModalOn(!resetPasswordModalOn);

  const isMobile = useMediaQuery("(max-width:500px)");

  const handleLogin = () => {
    login(
      {
        userIdx: userIdx,
        authKey: authKeyRef.current.value,
      },
      {
        onSuccess: (res) => {
          Swal.fire(
            alertModalConfig({
              text: "로그인되었습니다.",
              title: "로그인",
              iconHtml: checkIcon,
              confirmButtonText: "확인",
            })
          ).then((result) => {
            if (result.isConfirmed) {
              handleSuccessLogin(res);
            }
          });
        },
      }
    );
  };

  const handleAuth = async (data) => {
    mutate(
      {
        ...data,
      },
      {
        onSuccess: (res) => {
          setUserIdx(res.data.data);
          setLogin2fa(true);
          setTimerStart(true);
        },
        onError: (res) => {
          Swal.fire(
            alertModalConfig({
              text: res.response.data.message,
              title: "로그인",
              iconHtml: errorIcon,
              confirmButtonText: "확인",
            })
          );
        },
      }
    );
  };
  const handleReTry = () => {
    mutate(
      {
        ...getValues(),
      },
      {
        onSuccess: (res) => {
          setUserIdx(res.data.data);
          setTimerStart(true);
          setStartTime(180);
          setCount(180);
          authKey.current.focus();
        },
        onError: (res) => {
          Swal.fire(
            alertModalConfig({
              text: res.response.data.message,
              title: "로그인",
              iconHtml: errorIcon,
              confirmButtonText: "확인",
            })
          );
        },
      }
    );
  };
  const handleStopTimer = () => {
    setTimerStart(false);
  };

  useEffect(() => {
    if (count < 1) {
      handleStopTimer();
    }
  }, [count, timerStart, startTime]);

  useLayoutEffect(() => {
    login2fa && authKeyRef.current?.focus();
  }, [login2fa]);

  const handleKeyPress = (e, name) => {
    const values = getValues();
    if (e.key === "Enter") {
      if (name === "sendKey") {
        handleAuth(values);
        psRef.current.blur();
      } else if (name === "login") {
        handleLogin();
      }
    }
  };

  return (
    <Container maxWidth={false} disableGutters sx={bgContainer}>
      <Grid
        width={isMobile ? "25rem" : "30rem"}
        container
        p={4}
        justifyContent="center"
        bgcolor={"rgba( 255, 255, 255, 0.9 )"}
        boxShadow={"0 5px 15px 0 #d2d2d2"}
        borderRadius={2}
        textAlign="center"
        border={"1px solid rgba( 255, 255, 255, 0.18 )"}
      >
        <form style={{ width: "100%" }}>
          <Grid container direction="column" rowSpacing={2}>
            <Grid item xs>
              <img src={LOGO} style={{ width: 100, heigh: 80 }} />
            </Grid>

            <Grid item>
              <Typography fontWeight={800} variant="h5">
                [NEW ACG] HR 담당자
              </Typography>
            </Grid>
            <Grid item xs>
              <Grid item container flexDirection={"column"} rowGap={2}>
                <Grid item>
                  <Controller
                    name="userId"
                    control={control}
                    rules={{
                      required: {
                        value: true,
                        message: REQUIRE_VALUE,
                      },
                    }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        fullWidth
                        label={"ID"}
                        color={"neutral"}
                        error={errors.userId && true}
                      />
                    )}
                  />
                  <Typography color={"red"} variant="body2" textAlign={"right"}>
                    {errors.userId?.type === "required"
                      ? errors.userId.message
                      : null}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <FormControl sx={{ width: "100%" }} variant="outlined">
                    <InputLabel>비밀번호 입력</InputLabel>

                    <Controller
                      name="userPassword"
                      control={control}
                      rules={{
                        required: {
                          value: true,
                          message: REQUIRE_VALUE,
                        },
                      }}
                      render={({ field }) => (
                        <OutlinedInput
                          {...field}
                          type={showPassword ? "text" : "password"}
                          error={errors.userPassword && true}
                          onKeyUp={(e) => handleKeyPress(e, "sendKey")}
                          inputRef={psRef}
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                              >
                                {showPassword ? (
                                  <VisibilityOff />
                                ) : (
                                  <Visibility />
                                )}
                              </IconButton>
                            </InputAdornment>
                          }
                          label="비밀번호 입력"
                        />
                      )}
                    />
                    <Typography
                      color={"red"}
                      variant="body2"
                      textAlign={"right"}
                    >
                      {errors.userPassword?.type === "required"
                        ? errors.userPassword.message
                        : null}
                    </Typography>
                  </FormControl>
                  <Grid
                    item
                    container
                    alignItems="center"
                    justifyContent="flex-end"
                    columnGap={1}
                  >
                    <Typography
                      sx={{
                        "&": {
                          fontSize: 11,
                          fontWeight: 700,
                          cursor: "pointer",
                          mt: 0.5,
                          color: "#8e8e8e",
                          borderBottom: "1px solid transparent",
                          transition: "all 0.5s",
                        },
                        "&:hover": {
                          borderColor: "#8e8e8e",
                        },
                      }}
                      onClick={handleResetPasswordModal}
                    >
                      비밀번호 찾기
                    </Typography>
                  </Grid>
                </Grid>
                {login2fa ? (
                  <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 0.4 }}
                    style={{ width: "100%" }}
                  >
                    <Grid container columnGap={1}>
                      <Grid item xs>
                        <OutlinedInput
                          placeholder={
                            timerStart
                              ? "인증번호를 입력해 주세요."
                              : "인증번호를 재요청해 주세요."
                          }
                          size="small"
                          fullWidth
                          inputRef={authKeyRef}
                          sx={{
                            padding: 0,
                            "& input": {
                              width: "70%",
                            },
                            color: "#444444",
                          }}
                          onKeyUp={(e) => handleKeyPress(e, "login")}
                          endAdornment={`${addZero(
                            parseInt((count % 3600) / 60)
                          )} : ${addZero(count % 60)}`}
                        />
                      </Grid>
                      <Grid item>
                        <Button
                          variant="outlined"
                          sx={{ height: "100%" }}
                          onClick={handleReTry}
                          startIcon={<RotateCw size={15} />}
                        >
                          재요청
                        </Button>
                      </Grid>
                    </Grid>
                  </motion.div>
                ) : // <Grid
                //   item
                //   container
                //   alignItems="center"
                //   justifyContent={"space-between"}
                //   columnGap={1}
                // >
                //   <Grid item xs={5.5}>
                //     <Button
                //       fullWidth
                //       variant="outlined"
                //       startIcon={<UserPlus size={14} />}
                //       onClick={() => {
                //         Swal.fire(
                //           alertModalConfig({
                //             text: "ACG 담당자에게 문의해 주시기 바랍니다.",
                //             title: "회원가입",
                //             iconHtml: warningIcon,
                //             confirmButtonText: "확인",
                //           })
                //         );
                //       }}
                //     >
                //       회원가입
                //     </Button>
                //   </Grid>
                //   <Grid item xs={5.5}>
                //     <Button
                //       fullWidth
                //       variant="outlined"
                //       startIcon={<KeyRound size={14} />}
                //       onClick={() => {
                //         Swal.fire(
                //           alertModalConfig({
                //             text: "ACG 담당자에게 문의해 주시기 바랍니다.",
                //             title: "비밀번호 찾기",
                //             iconHtml: warningIcon,
                //             confirmButtonText: "확인",
                //           })
                //         );
                //       }}
                //     >
                //       비밀번호 찾기
                //     </Button>
                //   </Grid>
                // </Grid>
                null}

                <Grid item xs={12}>
                  {login2fa ? (
                    <Button
                      fullWidth
                      variant={"contained"}
                      onClick={handleLogin}
                      sx={{ "&": { fontSize: 13, fontWeight: 700 } }}
                      startIcon={<LogIn size={15} />}
                    >
                      로그인
                    </Button>
                  ) : (
                    <Button
                      fullWidth
                      color={"secondary"}
                      variant={"contained"}
                      onClick={handleSubmit(handleAuth)}
                      sx={{ "&": { fontSize: 13, fontWeight: 700 } }}
                    >
                      인증하기
                    </Button>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Grid>
      <Dialog
        open={resetPasswordModalOn}
        onClose={handleResetPasswordModal}
        fullWidth
        maxWidth="xs"
      >
        <Suspense fallback={<LoadingSkeleton />}>
          <ResetPasswordModal onClose={handleResetPasswordModal} />
        </Suspense>
      </Dialog>
    </Container>
  );
}

const bgContainer = {
  height: "100vh",
  width: "100vw",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};
