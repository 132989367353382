import { Stack, Typography } from "@mui/material";
import React from "react";
import { ReactComponent as Spinner } from "assets/icon/downloadSpinner-white.svg";
const DownloadLoadingBackdrop = ({ text }) => {
  return (
    <Stack direction="column" rowGap={1} alignItems="center">
      <Spinner />
      <Typography
        component="div"
        color="white"
        fontWeight={700}
        sx={{
          clipPath: "inset(0 2.5ch 0 0)",
          animation: "1s steps(4) infinite",
          fontSize: "15px",
          animationName: "l",
          "@keyframes l": {
            to: {
              clipPath: "inset(0 -1ch 0 0)",
            },
          },
        }}
      >
        {text}
      </Typography>
    </Stack>
  );
};

export default DownloadLoadingBackdrop;
