// export const URI = "http://192.168.219.122:3007";
export const URI = process.env.REACT_APP_API_URL;
export const uriDivert = (pilotFlag = false) => {
  if (pilotFlag === true) {
    return "https://pilotacgtest.insahr.co.kr";
  } else {
    if (process.env.NODE_ENV === "production") {
      return process.env.REACT_APP_API_URL_PROD;
    } else if (process.env.NODE_ENV === "development") {
      return process.env.REACT_APP_API_URL_DEV;
    }
  }
};

export const supervisorURL = () => {
  if (process.env.NODE_ENV === "production") {
    return process.env.REACT_APP_MANAGER_PROD;
  } else if (process.env.NODE_ENV === "development") {
    return process.env.REACT_APP_MANAGER_DEV;
  }
};
