import FilterListRoundedIcon from "@mui/icons-material/FilterListRounded";
import { IconButton, Menu, MenuItem } from "@mui/material";
import React from "react";

const GroupFilter = ({ setParamValues }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const setFilter = (filter) => {
    setParamValues((prev) => ({ ...prev, [filter]: "Y" }));
    handleClose();
  };

  return (
    <>
      <IconButton
        size="small"
        disableTouchRipple
        aria-describedby={id}
        variant="outlined"
        onClick={handleClick}
        sx={{
          border: "1px solid #ececec",
          borderRadius: 1,
          "&:hover": { border: "1px solid #b7b7b7" },
        }}
      >
        <FilterListRoundedIcon />
      </IconButton>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <MenuItem onClick={() => setFilter("testGroupYN")}>
          테스트 공고 별
        </MenuItem>
        <MenuItem onClick={() => setFilter("offlineGroupYN")}>
          오프라인 공고 별
        </MenuItem>
      </Menu>
    </>
  );
};

export default GroupFilter;
