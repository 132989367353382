import { lazy, Suspense, useCallback, useState } from "react";

// Style
import EmailRoundedIcon from "@mui/icons-material/EmailRounded";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import Dialog from "@mui/material/Dialog";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import TableContainer from "@mui/material/TableContainer";
import Tooltip from "@mui/material/Tooltip";
import { Trash2 } from "lucide-react";
import { tableHeader, tableWrapper } from "themes/theme";
// React-query
import useGetQueryhr from "hooks/useGetQueryhr";
import { useQueryClient } from "@tanstack/react-query";
import useMutationHook from "hooks/useMutationHook";
// Components
import TopTitle from "components/templates/topTitle/TopTitle";
import LoadingSkeleton from "components/UI/organism/LoadingSkeleton";

// Enum
import { STATS_MANAGER_LIST } from "enums/tableColumn";

// Router
import { useParams } from "react-router-dom";

// Recoil
import { useRecoilValue } from "recoil";
import { HRcompanyInfo } from "recoil/store";

// Utils
import { calcRowNum } from "utils/calcRowNum";
import { checkboxSelect } from "utils/checkboxSelect";
import { dateFormat } from "utils/formatter/dateFormat";

const SignUpEmailModal = lazy(() =>
  import(
    "components/pages/acg_admin/company/modules/userList/signUpEmailModal.jsx"
  )
);
const DeleteManagerModal = lazy(() =>
  import("components/pages/stats/DeleteManagerModal.jsx")
);
const ManagerList = () => {
  const queryClient = useQueryClient();
  const { company } = useParams();
  const [paramValues, setParamValues] = useState({
    pageNo: 1,
    perPage: 20,
  });
  const { data, pageInfo, isLoading, isFetching } = useGetQueryhr(
    "getManagers_CJ",
    paramValues,
    true,
    false
  );

  //* 권한수정
  const { mutate } = useMutationHook("updateGrade");

  const { userGradeIdx, companyIdx } = useRecoilValue(HRcompanyInfo);

  const [selected, setSelected] = useState([]);

  const [sendEmailModalOn, setSendEmailModalOn] = useState(false);
  const handleSendEmailModal = () => setSendEmailModalOn(!sendEmailModalOn);

  const [deleteManagerModalOpen, setDeleteManagerModalOpen] = useState(false);

  //* Mutation 후 이벤트
  const alertResult = useCallback((key) => {
    queryClient.invalidateQueries({
      queryKey: [key],
    });
  }, []);

  const handleGrade = (_, item) => {
    mutate(
      {
        userIdx: item.userIdx,
      },
      {
        onSuccess: () => {
          alertResult("getManagers_CJ");
        },
      }
    );
  };
  const handleCheckClick = (_, name) => {
    const { userIdx } = name;
    const selectedIndex = selected.indexOf(userIdx);
    setSelected(checkboxSelect(selected, selectedIndex, userIdx));
  };
  const isSelected = (name) => selected.indexOf(name) !== -1;

  const handleDeleteManager = () => setDeleteManagerModalOpen((prev) => !prev);

  return (
    <>
      <Box
        width="100%"
        height="100%"
        borderRadius={2}
        display="flex"
        flexDirection="column"
        rowGap={2}
      >
        <TopTitle
          list={[
            {
              title: "담당자 관리",
              url: `/acghr_hr/${company}/stats/manager`,
              current: true,
            },
          ]}
        />

        <Grid
          container
          justifyContent="space-between"
          rowGap={1}
          mt={3}
          alignItems="center"
        >
          <Box>
            <Grid item sm={12} container columnGap={2} alignItems="center">
              <Typography
                fontWeight={700}
                variant="h6"
              >{`총 ${pageInfo.total}명`}</Typography>
            </Grid>
          </Box>
          <Stack direction="row" columnGap={1}>
            <Tooltip
              title={
                userGradeIdx === 4
                  ? "담당자 삭제는 메인담당자만 가능합니다."
                  : null
              }
              arrow
            >
              <Box>
                <Button
                  variant="contained"
                  color="removeButton"
                  disableElevation
                  startIcon={<Trash2 size={15} />}
                  sx={{ "&": { color: "#c42c08" } }}
                  onClick={handleDeleteManager}
                  disabled={userGradeIdx === 4 ? true : false}
                >
                  담당자 삭제
                </Button>
              </Box>
            </Tooltip>
            <Button
              disableElevation
              variant="contained"
              startIcon={<EmailRoundedIcon />}
              onClick={handleSendEmailModal}
            >
              가입이메일 전송
            </Button>
          </Stack>
        </Grid>

        {isFetching || isLoading ? (
          <LoadingSkeleton />
        ) : (
          <Paper
            sx={{ ...(tableWrapper["height"] = "75%"), ...tableWrapper }}
            variant="outlined"
          >
            <TableContainer sx={{ height: "87%", maxHeight: "86%" }}>
              <Table stickyHeader sx={tableHeader} size="small">
                <TableHead>
                  <TableRow>
                    <TableCell padding="none" align="center" sx={{ width: 30 }}>
                      <Checkbox
                        size="small"
                        style={{ color: "#637381" }}
                        // onChange={handleSelectAllClick}
                      />
                    </TableCell>
                    {STATS_MANAGER_LIST.map((item) => {
                      return (
                        <TableCell
                          padding="none"
                          key={item.label}
                          width={item.size}
                          align="center"
                        >
                          {item.label}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data &&
                    data.data.map((item, index) => (
                      <TableRow
                        hover
                        sx={{
                          "&": { height: 45 },
                        }}
                        key={index}
                      >
                        <TableCell padding="none" align="center">
                          <Checkbox
                            size="small"
                            color="primary"
                            sx={{ padding: 0 }}
                            onClick={(event) => handleCheckClick(event, item)}
                            checked={isSelected(item.userIdx)}
                          />
                        </TableCell>
                        <TableCell padding="none" align="center">
                          {calcRowNum(index, pageInfo, 10)}
                        </TableCell>
                        <TableCell padding="none" align="center" width={25}>
                          <Select
                            size="small"
                            value={item.userGradeIdx}
                            onChange={(e) => handleGrade(e, item)}
                            sx={{
                              fontWeight: 700,
                              color:
                                item.userGradeIdx === 3 ? "#ec8921" : "#0080c5",
                            }}
                          >
                            <MenuItem value={4} key={1}>
                              통계 일반담당자
                            </MenuItem>
                            <MenuItem value={3} key={3}>
                              통계 메인담당자
                            </MenuItem>
                          </Select>
                        </TableCell>
                        <TableCell padding="none" align="center">
                          {item.userName}
                        </TableCell>
                        <TableCell padding="none" align="center">
                          {item.userCell}
                        </TableCell>
                        <TableCell padding="none" align="center">
                          {item.userTel || "-"}
                        </TableCell>
                        <TableCell padding="none" align="center">
                          {item.userEmail || "-"}
                        </TableCell>
                        <TableCell padding="none" align="center">
                          {dateFormat(item.updatedAt) || "-"}
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        )}
      </Box>
      {/* 가입 이메일 전송 모달 */}
      <Dialog
        open={sendEmailModalOn}
        onClose={handleSendEmailModal}
        maxWidth="xs"
        fullWidth
      >
        <Suspense fallback={"loading.."}>
          <SignUpEmailModal
            companyIdx={companyIdx}
            fromStatsManager={true}
            onClose={handleSendEmailModal}
          />
        </Suspense>
      </Dialog>

      {/* 관리자 삭제하기 */}
      <Dialog
        open={deleteManagerModalOpen}
        onClose={handleDeleteManager}
        maxWidth="xs"
        fullWidth
      >
        <Suspense fallback={<LoadingSkeleton />}>
          <DeleteManagerModal detail={selected} onClose={handleDeleteManager} />
        </Suspense>
      </Dialog>
    </>
  );
};

export default ManagerList;
