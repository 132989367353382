import { useQuery } from "@tanstack/react-query";
import { errorIcon } from "assets/icon/Icon_modal";
import { alertModalConfig } from "components/templates/modalConfig";
import { useState } from "react";
import Swal from "sweetalert2";
import { getHandler } from "../utils/requestURI";
import { hr_uris_GET } from "../utils/uris/hr_uris";

const useGetFile = (type = "HR", name, paramValues, enabledOption, setEnableOption, pilotFlag = false) => {
  const [data, setData] = useState(null);

  const uriFlag = (type = "HR", name, queryKey, pilotFlag) => {
    if (type === "HR") {
      return hr_uris_GET(name, queryKey, pilotFlag);
    } else {
      return getHandler(name, queryKey, pilotFlag);
    }
  };

  const { isLoading, isFetching, isError, refetch } = useQuery([name, paramValues], (queryKey) => uriFlag(type, name, queryKey, pilotFlag), {
    retry: false,
    enabled: enabledOption,
    keepPreviousData: true,
    onSuccess: (res) => {
      setEnableOption(false);
      setData(res);
      window.open(`https://${res.data.data.path}`);
    },
    onError: (err) => {
      Swal.fire(
        alertModalConfig({
          title: "파일 업로드",
          text: err.response.data.message,
          iconHtml: errorIcon,
          confirmButtonText: "확인",
        })
      );
      setEnableOption(false);
    },
  });

  return { data, isFetching, isLoading, isError, refetch };
};

export default useGetFile;
