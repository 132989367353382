import React, { useState } from "react";
import { dateIncludeTime } from "utils/formatter/dateFormat";
import { examStatusTagOnlyText } from "utils/tagRender";

const COPY_SUCCESS_MESSAGE = "클립보드에 복사되었습니다.";
const COPY_FAIL_MESSAGE = "클립보드에 복사를 실패하였습니다.";

const useCopyClipboard = (useFrom) => {
  const [copyResult, setCopyResult] = useState(false);
  const [isCopyError, setIsCopyError] = useState(false);
  const [copyResultMessage, setCopyResultMessage] = useState("");
  const handleCloseAlert = () => setCopyResult(!copyResult);

  const handleCopyClipBoard = async (text) => {
    let copyText = "";
    const {
      companyName,
      testerName,
      testerBirth,
      testerCell,
      groupName,
      examSAt,
      examStatus,
      roomNo,
      preExamYN,
      foreignLoginYN,
    } = text;

    if (useFrom === "roomTesterList") {
      copyText = `${companyName} / ${roomNo}번룸 /  ${testerName} / ${testerBirth} / ${testerCell}`;
    } else {
      copyText = `${companyName} / ${groupName} / ${dateIncludeTime(
        new Date(examSAt)
      )} / ${roomNo}번룸 / ${testerName} / ${testerBirth} / ${testerCell} / 사전점검 : ${preExamYN} / 해외응시 : ${foreignLoginYN} / ${examStatusTagOnlyText(
        examStatus
      )} `;
    }

    try {
      setCopyResult(true);
      await navigator.clipboard.writeText(copyText);
      setCopyResultMessage(COPY_SUCCESS_MESSAGE);
    } catch (e) {
      setIsCopyError(true);
      setCopyResultMessage(COPY_FAIL_MESSAGE);
    }
  };

  return {
    copyResultMessage,
    copyResult,
    handleCopyClipBoard,
    handleCloseAlert,
    isCopyError,
  };
};

export default useCopyClipboard;
