import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CardMedia from "@mui/material/CardMedia";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import useGetQueryhr from "hooks/useGetQueryhr";
import React, { useState } from "react";
import { useEffect } from "react";
import { aiType } from "utils/aiType";
import { dateFormatTimeAIChart, readableTimeMinutesSeconds } from "utils/formatter/dateFormat";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { tableHeader } from "themes/theme";
import { styled } from "@mui/material/styles";
import { ZoomIn } from "lucide-react";

const HtmlTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#f5f5f9",
    maxWidth: 220,
    border: "1px solid #dadde9",
  },
}));
const AiCountModal = ({ onClose, roomTesterInfo }) => {
  const [testerIdx] = useState(Number(roomTesterInfo?.testerIdx));

  const { data } = useGetQueryhr("aiLog", {
    testerIdx: testerIdx,
    perPage: 999,
    pageNo: 1,
  });

  const [partGroup, setPartGroup] = useState([{}]);

  useEffect(() => {
    const filteredArr = data?.data?.reduce((acc, current) => {
      const x = acc.find((item) => item.examSubName === current.examSubName);
      if (!x) {
        return acc.concat([current]);
      } else {
        return acc;
      }
    }, []);
    data &&
      setPartGroup([
        {
          part1: filteredArr?.map((arr) => {
            return data.data.filter((item) => item.examSubName === arr.examSubName);
          }),
        },
      ]);
  }, [data]);

  return (
    <>
      <DialogTitle mb={1}>
        <Grid container justifyContent={"space-between"} alignItems="center">
          <Typography variant="h5" fontWeight="bold">
            AI 감지 데이터
            <Typography component={"span"} fontWeight={700} ml={2} color="#477DA5" bgcolor="#E9F3F7" px={2} py={0.5} borderRadius={1} fontSize="1.2rem">
              {roomTesterInfo?.testerName}
            </Typography>
          </Typography>
        </Grid>
      </DialogTitle>

      <DialogContent sx={{ py: 0 }}>
        {data && data.data.length < 1 ? (
          <Box mx="auto" py={2}>
            <Typography fontWeight={700} textAlign="center">
              AI감지 기록이 없습니다.😮
            </Typography>
          </Box>
        ) : (
          <Grid container flexDirection="column" rowGap={1}>
            {partGroup[0].part1?.map((group, index) => {
              return (
                <Accordion
                  elevation={0}
                  key={index}
                  sx={{
                    bgcolor: "#f4f4f4",
                    borderRadius: 1,

                    width: "100%",
                    "&.Mui-expanded": { m: 0 },
                    "&::before": {
                      background: "transparent",
                    },
                  }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    sx={{
                      "&.Mui-expanded": { minHeight: 40 },
                      "&.Mui-expanded > div": { my: 1 },
                    }}
                  >
                    <Typography color={"#1E283D"} fontWeight={700}>
                      {group[0]?.examSubName}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails
                    sx={{
                      bgcolor: "#fff",
                      border: "1px solid #f4f4f4",
                      borderBottomLeftRadius: 7,
                      borderBottomRightRadius: 7,
                    }}
                  >
                    <Box>
                      <Paper variant="outlined">
                        <TableContainer sx={{ height: "87%", maxHeight: "87%" }}>
                          <Table size="small" stickyHeader sx={tableHeader}>
                            <TableHead>
                              <TableRow>
                                <TableCell padding="none" align="center">
                                  No
                                </TableCell>
                                <TableCell padding="none" align="center">
                                  남은 시간
                                </TableCell>
                                <TableCell padding="none" align="center">
                                  구분
                                </TableCell>
                                <TableCell padding="none" align="center">
                                  영역
                                </TableCell>
                                <TableCell padding="none" align="center">
                                  종류
                                </TableCell>
                                <TableCell padding="none" align="center">
                                  내용
                                </TableCell>
                                <TableCell padding="none" align="center">
                                  생성일자
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {group?.map((item, index) => {
                                return (
                                  <TableRow key={index}>
                                    <TableCell width={20} align="center" padding="none">
                                      {index + 1}
                                    </TableCell>
                                    <TableCell width={40} align="center" padding="none">
                                      {readableTimeMinutesSeconds(item.remainingSeconds)}
                                    </TableCell>
                                    <TableCell width={40} align="center" padding="none">
                                      {item.examName || "-"}
                                    </TableCell>
                                    <TableCell width={40} align="center" padding="none">
                                      {item.examSubName || "-"}
                                    </TableCell>
                                    <TableCell width={70} align="center">
                                      {item?.captureURL?.includes("https") ? (
                                        <HtmlTooltip
                                          placement="right"
                                          title={
                                            <React.Fragment>
                                              <CardMedia sx={{ height: 140 }} image={item.captureURL} component="img" />
                                            </React.Fragment>
                                          }
                                        >
                                          <Box position="relative">
                                            {aiType(item.aiTitle)}
                                            <Box
                                              position="absolute"
                                              top={"50%"}
                                              right={"10%"}
                                              sx={{
                                                transform: "translateY(-30%)",
                                              }}
                                            >
                                              <ZoomIn size={13} color="#a4a4a4" />
                                            </Box>
                                          </Box>
                                        </HtmlTooltip>
                                      ) : (
                                        <Box>{aiType(item.aiTitle)}</Box>
                                      )}
                                    </TableCell>
                                    <TableCell width={70} align="center">
                                      {!item.aiMessage.includes("https") && item.aiMessage}
                                    </TableCell>

                                    <TableCell width={70} align="center">
                                      {dateFormatTimeAIChart(new Date(item.createdAt))}
                                    </TableCell>
                                  </TableRow>
                                );
                              })}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Paper>
                    </Box>
                  </AccordionDetails>
                </Accordion>
              );
            })}
          </Grid>
        )}
      </DialogContent>
      <DialogActions sx={{ pb: 3, pl: 3 }}>
        <Button variant={"outlined"} onClick={onClose}>
          닫기
        </Button>
      </DialogActions>
    </>
  );
};

export default AiCountModal;
