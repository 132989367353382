import { lazy, Suspense, useEffect, useState } from "react";

// Style
import NoPhotographyIcon from "@mui/icons-material/NoPhotography";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import Dialog from "@mui/material/Dialog";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import Pagination from "@mui/material/Pagination";
import Paper from "@mui/material/Paper";
import Select from "@mui/material/Select";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import { questionIcon } from "assets/icon/Icon_modal";
import { tableHeader, tableWrapper } from "themes/theme";
// Router
import { useNavigate, useParams } from "react-router-dom";

// Recoil
import { useRecoilState } from "recoil";
import { perPageAtom, statsFilterAtom } from "recoil/store";

// Hooks
import useGetQueryhr from "hooks/useGetQueryhr";
import useMutationHook from "hooks/useMutationHook";

// Enum
import { STATS_TABLE } from "enums/tableColumn";

// Dialog
import Swal from "sweetalert2";
import { confirmModalConfig } from "components/templates/modalConfig";
// Components
import SearchBar from "components/pages/stats/SearchBar";
import TopTitle from "components/templates/topTitle/TopTitle";
import LoadingSkeleton from "components/UI/organism/LoadingSkeleton";
// Utils
import { calcRowNum } from "utils/calcRowNum";
import { checkboxSelect } from "utils/checkboxSelect";

import {
  dashDelimiter,
  dateIncludeTime,
  timeFormat,
} from "utils/formatter/dateFormat";
import { examMAPtype, tagRender } from "utils/tagRender";

const SummaryStatsModal = lazy(() =>
  import("components/pages/stats/SummaryStatsModal")
);

function Stats() {
  const [selected, setSelected] = useState([]);

  const [summaryModal, setSummaryModal] = useState(false);
  const handleShowSummary = () => {
    setSummaryModal(!summaryModal);
  };

  const date = new Date();

  const [searchFilter, setSearchFilter] = useRecoilState(statsFilterAtom);
  const [paramValues, setParamValues] = useState();

  const { mutate } = useMutationHook("downloadStatsList", "HR");
  const { mutate: tester_all } = useMutationHook("downloadTesterAll", "HR");

  //   //* QUERY
  const { data, pageInfo, isLoading, isFetching, refetch } = useGetQueryhr(
    "getTotalGroup_CJ",
    paramValues,
    true,
    false
  );

  const navigate = useNavigate();

  const { company } = useParams();

  const [perPage, setPerPage] = useRecoilState(perPageAtom);

  const handleCheckClick = (_, name) => {
    const { companyIdx } = name;
    const selectedIndex = selected.indexOf(companyIdx);
    setSelected(checkboxSelect(selected, selectedIndex, companyIdx));
  };

  const handlePerPage = (e) => {
    setPerPage(e.target.value);
    setParamValues((prev) => ({ ...prev, perPage: e.target.value }));
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const handleClickRow = (e, row) => {
    if (e.target.cellIndex >= 1) {
      navigate(`/acghr_hr/${company}/stats/${row.groupIdx}`, {
        state: { ...row },
      });
    }
  };
  const removeBlankKeys = (input, flagDownload = false) => {
    const temp = Object.assign({}, input);

    for (const key in temp) {
      if (key === "" || temp[key] === "") delete temp[key];
      if (key === "roomUseYN") {
        if (temp[key] === "TOTAL") delete temp[key];
      }
      if (key === "companyIdx") {
        if (temp[key] === -1) delete temp[key];
      }
      if (key === "examMAP") {
        if (temp[key] === "TOTAL") delete temp[key];
      }
      if (key === "sExamDate" || key === "eExamDate")
        temp[key] = timeFormat(new Date(temp[key]));
      if (flagDownload) {
        delete temp.pageNo;
        delete temp.perPage;
      }
    }
    return temp;
  };

  useEffect(() => {
    let result = removeBlankKeys(searchFilter);
    setParamValues(result);
  }, []);

  const handleListDownloadModal = () => {
    Swal.fire(
      confirmModalConfig({
        text: "리스트를 다운로드하시겠습니까?",
        title: "리스트 다운로드",
        iconHtml: questionIcon,
        confirmButtonText: "확인",
        cancelButtonText: "취소",
      })
    ).then((result) => {
      if (result.isConfirmed) {
        mutate(
          { ...removeBlankKeys(searchFilter, true) },
          {
            onSuccess: (res) => {
              window.open(`https://${res.data.data}`);
            },
          }
        );
      }
    });
  };

  const handleDownloadTester_All = () => {
    Swal.fire(
      confirmModalConfig({
        text: "모든 응시자 리스트를 다운로드하시겠습니까?",
        title: "리스트 다운로드",
        iconHtml: questionIcon,
        confirmButtonText: "확인",
        cancelButtonText: "취소",
      })
    ).then((result) => {
      if (result.isConfirmed) {
        tester_all(
          {
            sExamDate: dashDelimiter(searchFilter?.sExamDate),
            eExamDate: dashDelimiter(searchFilter?.eExamDate),
          },
          {
            onSuccess: (res) => {
              window.open(`https://${res.data.data}`);
            },
          }
        );
      }
    });
  };
  return (
    <>
      <Box
        width="100%"
        height="100%"
        borderRadius={2}
        display="flex"
        flexDirection="column"
        rowGap={2}
      >
        <TopTitle
          list={[
            {
              title: "채용그룹 관리",
              url: `#`,
              current: true,
            },
          ]}
        />

        <Paper
          sx={{
            ...tableWrapper,
            overflowY: "hidden",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            borderRadius: 2,
            p: 2,
          }}
          variant="outlined"
        >
          <SearchBar
            setParamValues={setParamValues}
            setSearchFilter={setSearchFilter}
            searchFilter={searchFilter}
            refetch={refetch}
          />

          <Grid
            container
            justifyContent="space-between"
            flexWrap="nowrap"
            alignItems="center"
            mb={1}
          >
            <Box>
              <Typography
                variant="h6"
                fontWeight={700}
              >{`총 ${pageInfo.total} 개`}</Typography>
            </Box>
            <Stack direction="row" columnGap={1}>
              <Select size="small" defaultValue={20} onChange={handlePerPage}>
                <MenuItem key={10} value={10}>
                  10개씩 보기
                </MenuItem>
                <MenuItem key={20} value={20}>
                  20개씩 보기
                </MenuItem>
                <MenuItem key={50} value={50}>
                  50개씩 보기
                </MenuItem>
              </Select>
              <Button
                variant="outlined"
                size="small"
                onClick={handleDownloadTester_All}
              >
                응시자 다운로드
              </Button>
              <Button variant="outlined" onClick={handleListDownloadModal}>
                현황 다운로드
              </Button>
              <Button variant="contained" onClick={handleShowSummary}>
                통계보기
              </Button>
            </Stack>
          </Grid>
          {isFetching || isLoading ? (
            <LoadingSkeleton />
          ) : (
            <>
              <TableContainer sx={{ height: "87%", maxHeight: "86%" }}>
                <Table stickyHeader sx={tableHeader} size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell padding="none" align="center" width={10}>
                        <Checkbox
                          size="small"
                          style={{ color: "#637381" }}
                          //   onChange={handleSelectAllClick}
                        />
                      </TableCell>
                      {STATS_TABLE.map((item) => {
                        return (
                          <TableCell
                            padding="none"
                            key={item.label}
                            width={item.size}
                            align="center"
                          >
                            {item.label}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data &&
                      data.data.map((item, index) => (
                        <TableRow
                          sx={{
                            "&": { height: 45, cursor: "pointer" },
                          }}
                          key={index}
                          onClick={(e) => handleClickRow(e, item)}
                        >
                          <TableCell padding="none" align="center">
                            <Checkbox
                              size="small"
                              color="primary"
                              sx={{ padding: 0 }}
                              onClick={(event) => handleCheckClick(event, item)}
                              checked={isSelected(item.companyIdx)}
                              //   onClick={(event) => handleCheckClick(event, row)}
                              //   checked={isSelected(row.groupIdx)}
                            />
                          </TableCell>
                          <TableCell padding="none" align="center">
                            {calcRowNum(index, pageInfo, perPage)}
                          </TableCell>
                          <TableCell padding="none" align="center">
                            {tagRender(item.groupStatus)}
                          </TableCell>
                          <TableCell padding="none" align="center">
                            {item.companyName}
                          </TableCell>

                          <TableCell padding="none">{item.groupName}</TableCell>
                          <TableCell padding="none" align="center">
                            {dateIncludeTime(new Date(item.examSAt))}
                          </TableCell>
                          <TableCell padding="none" align="center">
                            {dateIncludeTime(new Date(item.examEAt))}
                          </TableCell>
                          <TableCell padding="none" align="center">
                            {examMAPtype(item.examMAP)}
                          </TableCell>

                          <TableCell padding="none" align="center">
                            <Grid container justifyContent={"center"}>
                              {item.roomUseYN === "Y" ? (
                                <PhotoCameraIcon style={{ color: "#3e6ad1" }} />
                              ) : (
                                <NoPhotographyIcon
                                  style={{ color: "#ff6594" }}
                                />
                              )}
                            </Grid>
                          </TableCell>

                          <TableCell padding="none" align="center">
                            {item.total?.toLocaleString() || 0}
                          </TableCell>

                          <TableCell padding="none" align="center">
                            {item.done?.toLocaleString() || 0}
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>

              <Grid
                container
                alignItems={"center"}
                justifyContent="center"
                my={2}
              >
                <Pagination
                  count={pageInfo.totalPage}
                  page={pageInfo.pageNo}
                  onChange={(_, page) => {
                    setParamValues((prev) => ({
                      ...prev,
                      pageNo: page,
                    }));
                  }}
                />
              </Grid>
            </>
          )}
        </Paper>
      </Box>

      <Dialog
        maxWidth="md"
        fullWidth
        open={summaryModal}
        onClose={handleShowSummary}
      >
        <Suspense fallback={<LoadingSkeleton />}>
          <SummaryStatsModal
            date={{
              eExamDate: searchFilter.eExamDate,
              sExamDate: searchFilter.sExamDate,
            }}
            param={searchFilter}
            onClose={handleShowSummary}
          />
        </Suspense>
      </Dialog>
    </>
  );
}
export default Stats;
