import { Box, Typography } from "@mui/material";
import React from "react";

export default function GroupStatusTag({ status }) {
  const colorCode = () => {
    switch (status) {
      case "검사 진행":
        return {
          borderColor: "#42BFDB",
          bgcolor: "#42BFDB",
          fontColor: "#FEFEFE",
        };

      case "검사 대기":
        return {
          borderColor: "#42BFDB",
          bgcolor: "white",
          fontColor: "#42BFDB",
        };

      case "사전점검 진행":
        return {
          borderColor: "#42BFDB",
          bgcolor: "#2A93AA",
          fontColor: "white",
        };
      case "사전점검 대기":
        return {
          borderColor: "#195B69",
          bgcolor: "white",
          fontColor: "#195B69",
        };
      case "검사 종료":
        return {
          borderColor: "#ccc",
          bgcolor: "#ccc",
          fontColor: "#FEFEFE",
        };
      case "검사 미확정":
        return {
          borderColor: "#FF004E",
          bgcolor: "white",
          fontColor: "#FF004E",
        };
      case "채용 의뢰":
        return {
          borderColor: "#f6a66b",
          bgcolor: "#f6a66b",
          fontColor: "white",
        };
      case "예비 확정":
        return {
          borderColor: "#abd2e5",
          bgcolor: "#abd2e5",
          fontColor: "#607c9e",
        };
      default:
        return {
          borderColor: "none",
          bgcolor: "none",
          fontColor: "none",
        };
    }
  };
  return (
    <Box
      mx="auto"
      width={"95%"}
      border={`1px solid ${colorCode(status).borderColor}`}
      bgcolor={colorCode(status).bgcolor}
      borderRadius={1}
    >
      <Typography
        variant="body2"
        color={colorCode(status).fontColor}
        borderRadius={0.5}
        fontWeight={700}
        py={0.4}
      >
        {status || "-"}
      </Typography>
    </Box>
  );
}
