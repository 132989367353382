import React, { useState, useEffect, useRef } from "react";

// Style
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import OutlinedInput from "@mui/material/OutlinedInput";
import Stack from "@mui/material/Stack";
import { errorIcon } from "assets/icon/Icon_modal";

// Recoil
import { useRecoilState } from "recoil";
import { searchTesterValue } from "recoil/store";

// Dialog
import { alertModalConfig } from "components/templates/modalConfig";
import Swal from "sweetalert2";

// React-query
import useMutationHook from "hooks/useMutationHook";

export default function SearchTestersInput({ setParamValues, handleDuplicateTester, setDuplicateToggle, refetch }) {
  const [testerName, setTesterName] = useRecoilState(searchTesterValue);
  const [searchTester, setSearchTester] = useState(testerName || "");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const testerInputRef = useRef();

  //*  기응시자 목록 다운로드 Mutation
  const { mutate: downloadDuplicateTesters } = useMutationHook("downloadDuplicateTesters", "HR");
  const submitSearch = () => {
    setDuplicateToggle(false);
    setParamValues((prev) => {
      let temp = { ...prev };
      temp.searchTester.testerInfo = searchTester;
      temp.searchTester.pageNo = 1;
      return temp;
    });
    setTesterName(searchTester);
    refetch();
  };

  const handleInput = (e) => {
    setSearchTester(e.target.value);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      setDuplicateToggle(false);
      setParamValues((prev) => {
        let temp = { ...prev };
        temp.searchTester.testerInfo = searchTester;
        temp.searchTester.pageNo = 1;
        return temp;
      });
      setTesterName(searchTester);
      refetch();
    }
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDownloadDuplicateTester = () => {
    downloadDuplicateTesters(null, {
      onError: () => {
        Swal.fire(
          alertModalConfig({
            text: "기응시자 목록 다운로드를 실패하였습니다.",
            title: "기응시자 목록 다운로드",
            iconHtml: errorIcon,
            confirmButtonText: "확인",
          })
        );
      },
      onSuccess: (res) => {
        window.open(`https://${res.data.data}`);
      },
    });
  };

  useEffect(() => {
    testerInputRef.current.focus();
  }, []);

  return (
    <>
      <Grid container columnGap={1} justifyContent="space-between">
        <Stack direction="row" columnGap={1}>
          <Grid item>
            <OutlinedInput
              size="small"
              inputRef={testerInputRef}
              value={searchTester}
              onChange={handleInput}
              placeholder={`성명 또는 휴대전화 번호를 입력해 주세요.`}
              onKeyUp={handleKeyPress}
              sx={{ width: 300 }}
            />
          </Grid>
          <Grid item>
            <Button variant="outlined" onClick={submitSearch}>
              검색
            </Button>
          </Grid>
        </Stack>
        <Grid>
          <Button variant="contained" onClick={handleClick}>
            기응시 확인
          </Button>
          <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
            <MenuItem onClick={handleDuplicateTester}>기응시자 확인</MenuItem>
            <MenuItem onClick={handleDownloadDuplicateTester}>다운로드</MenuItem>
          </Menu>
        </Grid>
      </Grid>
    </>
  );
}
