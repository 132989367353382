import React, { lazy, Suspense, useEffect, useRef, useState } from "react";

// Style
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import Dialog from "@mui/material/Dialog";
import Fade from "@mui/material/Fade";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Pagination from "@mui/material/Pagination";
import Paper from "@mui/material/Paper";
import Select from "@mui/material/Select";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import {
  ArrowDownUp,
  MessagesSquare,
  Palette,
  Plus,
  Settings,
  ShoppingBag,
  Trash2,
  UndoDot,
  Users,
} from "lucide-react";
// Components
import LoadingSkeleton from "components/UI/organism/LoadingSkeleton";
import { SearchCompany } from "./read/SearchCompany";
import TopTitle from "components/templates/topTitle/TopTitle";

// Recoil
import { useRecoilState, useSetRecoilState } from "recoil";
import { companyIdxAtom, isLayoutPage, perPageAtom } from "recoil/store";

// Data Fetching
import { useQueryClient } from "@tanstack/react-query";
import useGetQuery from "hooks/useGetQuery";

// Router
import { useNavigate } from "react-router-dom";

// Modal
import { modalWrapper } from "components/templates/modalConfig";

// Enum
import { COMPANY_LIST_MAIN } from "enums/tableColumn";
import { iconBtnTheme, tableHeader, tableWrapper } from "themes/theme";

// Utils
import { checkboxSelect } from "utils/checkboxSelect";
import { dateFormat } from "utils/formatter/dateFormat";
import { examStatusTag } from "utils/tagRender";

const UpdateCompanyModal = lazy(() => import("./update/UpdateCompanyModal"));
const UserListModal = lazy(() => import("./modules/userList/UserListModal"));
const CreateCompanyModal = lazy(() => import("./create/CreateCompanyModal"));
const RemovedCompanyModal = lazy(() =>
  import("./modules/removedCompany/RemovedCompanyModal")
);
const DeleteCompanyModal = lazy(() => import("./delete/DeleteCompanyModal"));
const NormSetModal = lazy(() => import("./modules/normSet/NormSetModal"));
const ChatTemplateModal = lazy(() =>
  import("./modules/chatTemplate/ChatTemplateModal")
);

function CompanyList() {
  // RECOIL
  const setCompanyIdx = useSetRecoilState(companyIdxAtom);
  const setIsLayout = useSetRecoilState(isLayoutPage);
  const [perPage, setPerPage] = useRecoilState(perPageAtom);
  // STATE || REF
  const [companies, setCompanies] = useState([]);
  const [selected, setSelected] = useState([]);
  const [selectedItem, setSelectedItem] = useState({});
  const [paramValues, setParamValues] = useState({
    page: { pageNo: 1 },
    search: { companyName: "" },
  });
  const [modals, setModals] = useState({
    createCompany: false,
    userList: false,
    updateCompany: false,
    removedCompany: false,
    deleteCompany: false,
    normSet: false,
    chatTemplate: false,
  });
  const [anchorGenerate, setAnchorGenerate] = useState(null);
  const [anchorName, setAnchorName] = useState(null);
  const genRef = useRef();
  // REACT-QUERY
  const queryClient = useQueryClient();
  const { data, pageInfo, isLoading, isFetching, refetch } = useGetQuery(
    "totalCompany",
    paramValues,
    true,
    false
  );
  // CONSTANT
  const openGenerate = Boolean(anchorGenerate);
  const openName = Boolean(anchorName);

  // NAVIGATE
  const navigate = useNavigate();
  const handleRowClick = (_, row) => setSelectedItem(row);

  //* CHECK BOX
  const handleCheckClick = (_, name) => {
    const { companyIdx } = name;
    const selectedIndex = selected.indexOf(companyIdx);
    setSelected(checkboxSelect(selected, selectedIndex, companyIdx));
  };
  const isSelected = (name) => selected.indexOf(name) !== -1;
  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = companies.map((n) => n.companyIdx);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  useEffect(() => {
    setPerPage(20);
    setIsLayout(false);
    queryClient.invalidateQueries({
      queryKey: ["totalCompany"],
    });
    data && setCompanies(data.companyList);
  }, [data, queryClient, setIsLayout, setPerPage]);

  //* SORT HANDLER
  const handleClick = (event, customName) => {
    const { name } = event.target;
    if (customName === "generate" || name === "generate")
      setAnchorGenerate(event.currentTarget);
    if (customName === "name" || name === "name")
      setAnchorName(event.currentTarget);
  };

  const filter = (e, name = "") => {
    setParamValues((prev) => ({
      ...prev,
      orderType: name,
      orderBy: e.currentTarget.getAttribute("name") || "",
    }));
  };

  const handleCloseGenerate = () => setAnchorGenerate(null);
  const handleCloseName = () => setAnchorName(null);

  //* PER PAGE
  const handlePerPage = (e) => {
    setPerPage(e.target.value);
    setParamValues((prev) => ({
      ...prev,
      perPage: e.target.value,
    }));
  };

  //* DIALOG
  const afterConfirm = (row) => {
    setCompanyIdx(row.companyIdx);
    navigate("/company/layout", {
      state: row,
    });
  };

  const handleMoveUItemplate = (row) => {
    modalWrapper({
      text: "레이아웃 디자인 페이지로 이동하시겠습니까?",
      title: "레이아웃 디자인 페이지",
      icon: "question",
      afterConfirmAction: () => afterConfirm(row),
    });
  };

  const handleModals = (name) => {
    setModals((prev) => ({ ...prev, [name]: !prev[name] }));
  };
  return (
    <>
      <Box
        width="100%"
        height="100%"
        borderRadius={2}
        display="flex"
        flexDirection="column"
        rowGap={2}
      >
        <TopTitle
          list={[
            {
              title: "고객사 관리",
              url: `/company`,
              current: true,
            },
          ]}
        />

        {isLoading || isFetching ? (
          <LoadingSkeleton />
        ) : (
          <Paper
            sx={{
              ...tableWrapper,
              overflowY: "hidden",
              height: "100%",
              display: "flex",
              flexDirection: "column",
              borderRadius: 2,
              p: 2,
            }}
            variant="outlined"
          >
            <SearchCompany setParamValues={setParamValues} refetch={refetch} />
            {/* <Box> */}
            <Grid
              container
              justifyContent="space-between"
              bgcolor="#fdfdfd"
              mt={2}
              borderBottom="1px solid rgba(224, 224, 224, 1)"
              py={1}
            >
              <Box>
                <Grid container alignItems={"center"}>
                  <Select
                    size="small"
                    defaultValue={perPage}
                    onChange={handlePerPage}
                  >
                    <MenuItem key={10} value={10}>
                      10/Page
                    </MenuItem>
                    <MenuItem key={20} value={20}>
                      20/Page
                    </MenuItem>
                    <MenuItem key={50} value={50}>
                      50/Page
                    </MenuItem>
                  </Select>
                  <Typography
                    variant="body"
                    fontWeight={700}
                    ml={1}
                  >{`등록된 고객사 : ${pageInfo.total}개`}</Typography>
                </Grid>
              </Box>
              <Box>
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Box>
                    <Grid container columnGap={1} alignItems="center">
                      <Button
                        sx={{
                          paddingLeft: "19px",
                          "& .MuiButton-endIcon": {
                            marginLeft: 0.3,
                          },
                          "& .MuiSvgIcon-root": {
                            color: "#777777",
                            fontSize: 15.5,
                          },
                        }}
                        variant="outlined"
                        disableElevation
                        name={"generate"}
                        ref={genRef}
                        onClick={handleClick}
                        endIcon={
                          <ArrowDownUp
                            size={14}
                            strokeWidth={1}
                            onClick={(e) => handleClick(e, "generate")}
                          />
                        }
                      >
                        등록순
                      </Button>
                      <Menu
                        anchorEl={genRef.current}
                        open={openGenerate}
                        name={"generate"}
                        onClose={handleCloseGenerate}
                        transformOrigin={{
                          horizontal: "right",
                          vertical: "top",
                        }}
                        anchorOrigin={{
                          horizontal: "right",
                          vertical: "bottom",
                        }}
                      >
                        <MenuItem
                          key="ASC_generate"
                          name="DESC"
                          onClick={(e) => {
                            filter(e, "GENERATE");
                            handleCloseGenerate();
                          }}
                          sx={{ fontSize: "0.8rem" }}
                        >
                          최신순
                        </MenuItem>
                        <MenuItem
                          key="DESC_generate"
                          name="ASC"
                          onClick={(e) => {
                            filter(e, "GENERATE");
                            handleCloseGenerate();
                          }}
                          sx={{ fontSize: "0.8rem" }}
                        >
                          나중순
                        </MenuItem>
                      </Menu>

                      <Button
                        sx={{
                          paddingLeft: "19px",
                          "& .MuiButton-endIcon": {
                            marginLeft: 0.3,
                          },
                          "& .MuiSvgIcon-root": {
                            color: "#777777",
                            fontSize: 15.5,
                          },
                        }}
                        variant="outlined"
                        name={"name"}
                        disableElevation
                        onClick={handleClick}
                        endIcon={
                          <ArrowDownUp
                            size={14}
                            strokeWidth={1}
                            onClick={(e) => handleClick(e, "name")}
                            name={"name"}
                          />
                        }
                      >
                        이름순
                      </Button>
                      <Menu
                        anchorEl={anchorName}
                        open={openName}
                        onClose={handleCloseName}
                        transformOrigin={{
                          horizontal: "right",
                          vertical: "top",
                        }}
                        anchorOrigin={{
                          horizontal: "right",
                          vertical: "bottom",
                        }}
                      >
                        <MenuItem
                          key="ASC_name"
                          name="ASC"
                          onClick={(e) => {
                            filter(e, "NAME");
                            handleCloseName();
                          }}
                          sx={{ fontSize: "0.8rem" }}
                        >
                          ㄱ~ㅎ
                        </MenuItem>
                        <MenuItem
                          key="DESC_name"
                          name="DESC"
                          onClick={(e) => {
                            filter(e, "NAME");
                            handleCloseName();
                          }}
                          sx={{ fontSize: "0.8rem" }}
                        >
                          ㅎ~ㄱ
                        </MenuItem>
                      </Menu>
                      <Button
                        variant="outlined"
                        disableElevation
                        onClick={() => handleModals("removedCompany")}
                        startIcon={<UndoDot size={15} />}
                        sx={{
                          "&": {
                            color: "#08b41a",
                          },
                        }}
                      >
                        복원
                      </Button>
                      <Tooltip
                        TransitionComponent={Fade}
                        title="한번에 1개씩만 삭제 가능합니다."
                        placement="top-start"
                        arrow
                      >
                        <Box component="span">
                          <Button
                            startIcon={<Trash2 size={15} />}
                            variant="contained"
                            color="removeButton"
                            disableElevation
                            onClick={() => handleModals("deleteCompany")}
                            sx={{ "&": { color: "#c42c08" } }}
                          >
                            선택항목 삭제
                          </Button>
                        </Box>
                      </Tooltip>

                      <Button
                        variant="contained"
                        disableElevation
                        onClick={() => handleModals("createCompany")}
                        startIcon={<Plus size={15} />}
                      >
                        회사등록
                      </Button>
                    </Grid>
                  </Box>
                </Grid>
              </Box>
            </Grid>
            {/* </Box> */}

            <TableContainer sx={{ height: "calc(95% - 20px)" }}>
              <Table stickyHeader sx={tableHeader} size="small">
                <TableHead>
                  <TableRow>
                    <TableCell padding="none" align="center" sx={{ width: 30 }}>
                      <Checkbox
                        size="small"
                        style={{ color: "#637381" }}
                        onChange={handleSelectAllClick}
                      />
                    </TableCell>
                    {COMPANY_LIST_MAIN.map((item) => {
                      return (
                        <TableCell
                          padding="none"
                          key={item.label}
                          sx={{ width: item.size, fontWeight: "bold" }}
                          align="center"
                        >
                          {item.label}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data &&
                    data.companyList.map((row, index) => (
                      <TableRow
                        hover
                        key={row.companyIdx}
                        sx={{
                          "&": { height: 45 },
                          "& > td": { borderBottom: "1px solid #ebebeb" },
                        }}
                        tabIndex={-1}
                      >
                        <TableCell align="center" padding="none">
                          <Checkbox
                            size="small"
                            color="primary"
                            onClick={(event) => handleCheckClick(event, row)}
                            checked={isSelected(row.companyIdx)}
                          />
                        </TableCell>

                        {/* 진행상황 */}
                        <TableCell
                          align="center"
                          padding="none"
                          sx={{ cursor: "pointer" }}
                        >
                          {examStatusTag(row.companyStatus)}
                        </TableCell>

                        {/* 고객사명 */}
                        <TableCell align="center" padding="none">
                          {row.companyName}
                        </TableCell>

                        {/* 대표번호 */}
                        <TableCell align="center" padding="none">
                          {row.companyTel}
                        </TableCell>

                        {/* 담당자 관리 */}

                        <TableCell align="center" padding="none">
                          <IconButton
                            size="small"
                            onClick={(event) => {
                              handleModals("userList");
                              handleRowClick(event, row);
                            }}
                            sx={iconBtnTheme}
                          >
                            <Users size={20} strokeWidth={1.5} />
                          </IconButton>
                        </TableCell>

                        {/* 검사세트 관리 */}

                        <TableCell align="center" padding="none">
                          <IconButton
                            size="small"
                            onClick={(event) => {
                              handleRowClick(event, row);
                              handleModals("normSet");
                            }}
                            sx={iconBtnTheme}
                          >
                            <ShoppingBag size={20} strokeWidth={1.5} />
                          </IconButton>
                        </TableCell>

                        {/* UI템플릿 */}

                        <TableCell align="center" padding="none">
                          <IconButton
                            size="small"
                            onClick={() => handleMoveUItemplate(row)}
                            sx={iconBtnTheme}
                          >
                            <Palette size={20} strokeWidth={1.5} />
                          </IconButton>
                        </TableCell>

                        {/* 수정 */}
                        <TableCell align="center" padding="none">
                          <IconButton
                            size="small"
                            onClick={(event) => {
                              handleRowClick(event, row);
                              handleModals("updateCompany");
                            }}
                            sx={iconBtnTheme}
                          >
                            <Settings size={20} strokeWidth={1.5} />
                          </IconButton>
                        </TableCell>

                        {/* 채팅템플릿 */}

                        <TableCell align="center" padding="none">
                          <IconButton
                            size="small"
                            onClick={(event) => {
                              handleRowClick(event, row);
                              handleModals("chatTemplate");
                            }}
                            sx={iconBtnTheme}
                          >
                            <MessagesSquare size={20} strokeWidth={1.5} />
                          </IconButton>
                        </TableCell>

                        {/* 등록일 */}
                        <TableCell align="center" padding="none">
                          {dateFormat(row.createdAt)}
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>

            <Grid container alignItems={"center"} justifyContent="center">
              <Pagination
                size="small"
                count={pageInfo.totalPage}
                page={pageInfo.pageNo}
                onChange={(_, page) => {
                  setParamValues((prev) => ({
                    ...prev,
                    pageNo: page,
                  }));
                }}
              />
            </Grid>
          </Paper>
        )}
      </Box>

      {/* 고객사 생성 */}
      <Dialog
        open={modals.createCompany}
        onClose={() => handleModals("createCompany")}
        fullWidth
        maxWidth="sm"
      >
        <Suspense fallback={<LoadingSkeleton />}>
          <CreateCompanyModal
            companyInfo={selectedItem}
            onClose={() => handleModals("createCompany")}
          />
        </Suspense>
      </Dialog>
      {/* 담당자관리 */}
      <Dialog
        open={modals.userList}
        onClose={() => handleModals("userList")}
        fullWidth
        maxWidth={"sm"}
        scroll={"paper"}
      >
        <Suspense fallback={<LoadingSkeleton />}>
          <UserListModal
            companyInfo={selectedItem}
            onClose={() => handleModals("userList")}
          />
        </Suspense>
      </Dialog>
      {/* 고객사 수정 */}
      <Dialog
        open={modals.updateCompany}
        onClose={() => handleModals("updateCompany")}
        fullWidth
        maxWidth="sm"
      >
        <Suspense fallback={<LoadingSkeleton />}>
          <UpdateCompanyModal
            companyInfo={selectedItem}
            onClose={() => handleModals("updateCompany")}
          />
        </Suspense>
      </Dialog>
      {/* 삭제된고객사 */}
      <Dialog
        open={modals.removedCompany}
        onClose={() => handleModals("removedCompany")}
        maxWidth="xs"
        fullWidth
      >
        <Suspense fallback={<LoadingSkeleton />}>
          <RemovedCompanyModal
            companyInfo={selectedItem}
            onClose={() => handleModals("removedCompany")}
          />
        </Suspense>
      </Dialog>
      {/* 고객사 삭제하기 */}
      <Dialog
        open={modals.deleteCompany}
        onClose={() => handleModals("deleteCompany")}
        maxWidth="xs"
        fullWidth
      >
        <Suspense fallback={<LoadingSkeleton />}>
          <DeleteCompanyModal
            detail={selected}
            onClose={() => handleModals("deleteCompany")}
            setSelected={setSelected}
          />
        </Suspense>
      </Dialog>
      {/* 검사세트관리 */}
      <Dialog
        open={modals.normSet}
        onClose={() => handleModals("normSet")}
        fullWidth
        maxWidth="md"
      >
        <Suspense fallback={<LoadingSkeleton />}>
          <NormSetModal
            detail={selected}
            companyInfo={selectedItem}
            onClose={() => handleModals("normSet")}
          />
        </Suspense>
      </Dialog>
      {/* 채팅템플릿관리 */}
      <Dialog
        open={modals.chatTemplate}
        onClose={() => handleModals("chatTemplate")}
        maxWidth={"md"}
        fullWidth
        scroll={"paper"}
      >
        <Suspense fallback={<LoadingSkeleton />}>
          <ChatTemplateModal
            companyInfo={selectedItem}
            onClose={() => handleModals("chatTemplate")}
          />
        </Suspense>
      </Dialog>
    </>
  );
}
export default React.memo(CompanyList);
