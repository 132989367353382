import { Box, Button, Checkbox, Grid, Typography } from "@mui/material";
import React from "react";
import LOGO from "assets/image/logo/ACG_CI-그레이1.png";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import { Buffer } from "buffer";
const Title = (props) => (
  <Typography
    sx={{
      fontWeight: 700,
      wordBreak: "keep-all",

      "&:after": {
        content: props.isRequire ? '"(필수)"' : null,
        fontSize: 13,
        fontWeight: 500,
        color: "red",
      },
    }}
  >
    {props.children}
  </Typography>
);
const SignUpHRterm = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const query = location.search;
  const decoded = query.split("&").map((item) => {
    return Buffer.from(item.slice(item.indexOf("=") + 1), "base64").toString(
      "utf8"
    );
  });

  const [isAgreeAll, setIsAgreeAll] = useState(false);

  const [isChecked, setIsChecked] = useState({
    checkbox1: false,
    checkbox2: false,
  });

  const handleCheckBox = (_, name) => {
    setIsChecked((prev) => ({ ...prev, [name]: !isChecked[name] }));
  };

  const handleIsAllCheck = (isChecked) => {
    if (Object.values(isChecked).indexOf(false) === -1) setIsAgreeAll(true);
    else setIsAgreeAll(false);
  };

  const handleAllCheck = (e) => {
    setIsChecked((prev) => ({
      ...prev,
      checkbox1: e.target.checked,
      checkbox2: e.target.checked,
    }));
  };

  const handleNextPage = () => {
    navigate("/signUp_hr", {
      state: { companyCode: decoded[0], userIdx: Number(decoded[1]) },
    });
  };

  useEffect(() => {
    handleIsAllCheck(isChecked);
  }, [isChecked]);

  return (
    <Grid
      container
      height={"100vh"}
      justifyContent={"center"}
      alignItems="center"
    >
      <Grid
        boxShadow={"20px 20px 90px #d9d9d9, -20px -20px 60px #ffffff"}
        width={"95%"}
        height={"95%"}
        borderRadius={2}
        position="relative"
        container
        flexDirection="column"
        alignItems="center"
        justifyContent={"center"}
      >
        {/* LOGO */}
        <Box position={"absolute"} left={30} top={30}>
          <Box component={"img"} src={LOGO} width={70} />
        </Box>

        <Grid
          item
          container
          alignItems={"center"}
          flexDirection="column"
          p={3}
          maxWidth={400}
          rowGap={3}
        >
          {/* Title */}
          <Grid item container alignItems={"center"} flexDirection="column">
            <Typography variant="h5" fontWeight={700} textAlign="center">
              회원가입
            </Typography>
          </Grid>

          {/* 모두선택 */}
          <Grid item container alignItems={"center"} flexWrap="nowrap">
            <Checkbox onClick={handleAllCheck} checked={isAgreeAll} />
            <Title>ACG 이용약관,개인정보 수집 및 이용에 모두 동의합니다.</Title>
          </Grid>
          {/* 1st */}
          <Grid container alignItems={"center"} flexDirection="column">
            <Grid item container alignItems={"center"}>
              <Checkbox
                onClick={(e) => handleCheckBox(e, "checkbox1")}
                checked={isChecked.checkbox1}
              />
              <Title isRequire={true}>ACG 이용약관 동의</Title>
            </Grid>
            <Grid item>
              <Box
                sx={{
                  maxHeight: 100,
                  overflow: "auto",
                  background: "white",
                  borderRadius: 2,
                  wordBreak: "keep-all",
                  whiteSpace: "pre-wrap",
                  p: 2,
                }}
              >
                {term1}
              </Box>
            </Grid>
          </Grid>
          {/* 2nd */}
          <Grid container alignItems={"center"} flexDirection="column">
            <Grid item container alignItems={"center"}>
              <Checkbox
                onClick={(e) => handleCheckBox(e, "checkbox2")}
                checked={isChecked.checkbox2}
              />
              <Title isRequire={true}>개인정보 수집 및 이용 동의</Title>
            </Grid>
            <Grid item>
              <Box
                sx={{
                  maxHeight: 100,
                  overflow: "auto",
                  background: "white",
                  borderRadius: 2,
                  wordBreak: "keep-all",
                  whiteSpace: "pre-wrap",
                  p: 2,
                }}
              >
                {term2}
              </Box>
            </Grid>
          </Grid>

          <Grid container columnGap={2} justifyContent="center">
            <Link to="/login_hr">
              <Button variant="outlined">취소</Button>
            </Link>
            <Button
              variant="contained"
              onClick={handleNextPage}
              disabled={isAgreeAll ? false : true}
            >
              다음
            </Button>
          </Grid>
        </Grid>
        {/* right */}
      </Grid>
    </Grid>
  );
};

export default SignUpHRterm;
const term1 = `
여러분을 환영합니다.
ACG 서비스 및 시스템(이하 ‘서비스’)을 이용해 주셔서 감사합니다. 
본 약관은 다양한 ACG 서비스의 이용과 관련하여 ACG 서비스를 제공하는 ㈜에이시지알(이하 ‘ACG’)과 이를 이용하는 ACG 서비스 이용자(이하 ‘고객사’) 또는 비고객사와의 관계를 설명하며, 아울러 여러분의 ACG 서비스 이용에 도움이 될 수 있는 유익한 정보를 포함하고 있습니다.

ACG 서비스를 이용하시거나 ACG 서비스 고객사로 가입하실 경우 여러분은 본 약관 및 관련 운영 정책을 확인하거나 동의하게 되므로, 잠시 시간을 내시어 주의 깊게 살펴봐 주시기 바랍니다.

고객사로 가입하시면 ACG 서비스를 보다 편리하게 이용할 수 있습니다.
여러분은 본 약관을 읽고 동의하신 후 고객사 가입을 신청하실 수 있으며, ACG는 이에 대한 승낙을 통해 고객사 가입 절차를 완료하고 여러분께 ACG 서비스 이용 계정(이하 ‘계정’)을 부여합니다. 계정이란 고객사가 ACG 서비스에 로그인한 이후 이용하는 각종 서비스 이용 이력을 고객사 별로 관리하기 위해 설정한 고객사 식별 단위를 말합니다. 

여러분의 개인정보를 소중히 보호합니다.
ACG는 서비스의 원활한 제공을 위하여 고객사가 동의한 목적과 범위 내에서만 개인정보를 수집∙이용하며, 개인정보 보호 관련 법령에 따라 안전하게 관리합니다. ACG가 이용자 및 고객사에 대해 관련 개인정보를 안전하게 처리하기 위하여 기울이는 노력이나 기타 상세한 사항은 개인정보 처리방침에서 확인하실 수 있습니다.

ACG는 여러분이 서비스를 이용하기 위해 일정 기간 동안 로그인 혹은 접속한 기록이 없는 경우, 전자메일, 서비스 내 알림 또는 기타 적절한 전자적 수단을 통해 사전에 안내해 드린 후 여러분의 정보를 파기하거나 분리 보관할 수 있으며, 만약 이로 인해 서비스 제공을 위해 필수적인 정보가 부족해질 경우 부득이 관련 서비스 이용계약을 해지할 수 있습니다.

ACG 서비스 이용과 관련하여 몇 가지 주의사항이 있습니다.
ACG는 여러분이 ACG 서비스를 자유롭고 편리하게 이용할 수 있도록 최선을 다하고 있습니다. 다만, 여러분이 ACG 서비스를 보다 안전하게 이용하고 ACG 서비스에서 여러분과 타인의 권리가 서로 존중되고 보호받으려면 여러분의 도움과 협조가 필요합니다. 여러분의 안전한 서비스 이용과 권리 보호를 위해 부득이 아래와 같은 경우 ACG 서비스 이용이 제한될 수 있으므로, 이에 대한 확인 및 준수를 요청 드립니다.

회원가입 시 이름, 휴대전화 번호 등의 정보를 허위로 기재해서는 안 됩니다. 
고객사 계정에 등록된 정보는 항상 정확한 최신 정보가 유지될 수 있도록 관리해 주세요. 자신의 계정을 다른 사람에게 판매, 양도, 대여 또는 담보로 제공하거나 다른 사람에게 그 사용을 허락해서는 안 됩니다. 아울러 자신의 계정이 아닌 타인의 계정을 무단으로 사용해서는 안 됩니다. 이에 관한 상세한 내용은 계정 운영정책을 참고해 주시기 바랍니다.
ACG의 사전 허락 없이 자동화된 수단(예: 매크로 프로그램, 로봇(봇), 스파이더, 스크래퍼 등)을 이용하여 ACG 서비스 고객사로 가입을 시도 또는 가입하거나, ACG 서비스에 로그인을 시도 또는 로그인하거나, ACG 서비스 상에 게시물을 게재하거나, ACG 서비스를 통해 커뮤니케이션하거나(예: 전자메일 등), ACG 서비스에 게재된 고객사의 아이디(ID), 게시물 등을 수집하거나, 이용자(사람)의 실제 이용을 전제로 하는 ACG 서비스의 제공 취지에 부합하지 않는 방식으로 ACG 서비스를 이용하거나 이와 같은 ACG 서비스에 대한 어뷰징(남용) 행위를 막기 위한 ACG의 기술적 조치를 무력화하려는 일체의 행위(예: IP를 지속적으로 바꿔가며 접속하는 행위를 시도해서는 안 됩니다.
또한, ACG 서비스 또는 이에 포함된 소프트웨어를 복사, 수정할 수 없으며, 이를 판매, 양도, 대여 또는 담보로 제공하거나 타인에게 그 이용을 허락해서는 안 됩니다. ACG 서비스에 포함된 소프트웨어를 역 설계, 소스코드 추출 시도, 복제, 분해, 모방, 기타 변형하는 등의 행위도 금지됩니다(다만, 오픈소스에 해당되는 경우 그 자체 조건에 따릅니다). 그 밖에 바이러스나 기타 악성 코드를 업로드하거나 ACG 서비스의 원활한 운영을 방해할 목적으로 서비스 기능을 비정상적으로 이용하는 행위 역시 금지됩니다.

또한 여러분이 관련 법령, 본 약관, 계정 및 운영정책, 각 개별 서비스에서의 약관, 운영정책 등을 준수하지 않을 경우, ACG는 여러분의 관련 행위 내용을 확인할 수 있으며, 그 확인 결과에 따라 ACG 서비스 이용에 대한 주의를 당부하거나, ACG 서비스 이용을 일부 또는 전부, 일시 또는 영구히 정지시키는 등 그 이용을 제한할 수 있습니다. 한편, 이러한 이용 제한에도 불구하고 더 이상 ACG 서비스 이용계약의 온전한 유지를 기대하기 어려운 경우엔 부득이 여러분과의 이용계약을 해지할 수 있습니다.

부득이 여러분의 서비스 이용을 제한해야 할 경우 명백한 법령 위반이나 타인의 권리침해로서 긴급한 위험 또는 피해 차단이 요구되는 사안 외에는 위와 같은 단계적 서비스 이용제한 원칙을 준수 하겠습니다. 명백한 법령 위반 등을 이유로 부득이 서비스 이용을 즉시 영구 정지시키는 경우 이에 대해 별도로 보상하지 않으므로 유의해 주시기 바랍니다. 서비스 이용 제한의 조건, 세부 내용 등은 계정 운영정책 및 각 개별 서비스에서의 운영정책을 참고하시기 바랍니다.

ACG의 잘못은 ACG가 책임집니다.
ACG는 여러분이 ACG 서비스를 이용함에 있어 ACG의 고의 또는 과실로 인하여 손해를 입게 될 경우 관련 법령에 따라 손해를 배상합니다. 다만, 천재지변 또는 이에 준하는 불가항력으로 인하여 ACG가 서비스를 제공할 수 없거나 이용자의 고의 또는 과실로 인하여 서비스를 이용할 수 없어 발생한 손해에 대해서 ACG는 책임을 부담하지 않습니다.

그리고 ACG가 손해배상책임을 부담하는 경우에도 통상적으로 예견이 불가능하거나 특별한 사정으로 인한 특별 손해 또는 간접 손해, 기타 징벌적 손해에 대해서는 관련 법령에 특별한 규정이 없는 한 책임을 부담하지 않습니다.

ACG 서비스 이용계약이 해지되면, 관련 법령 및 개인정보처리방침에 따라 ACG가 해당 고객사의 정보를 보유할 수 있는 경우를 제외하고, 해당 고객사 계정에 부속된 게시물 일체를 포함한 고객사의 모든 데이터는 소멸됨과 동시에 복구할 수 없게 됩니다. 

서비스 중단 또는 변경 시 꼭 알려드리겠습니다.
ACG는 연중 무휴, 1일 24시간 안정적으로 서비스를 제공하기 위해 최선을 다하고 있습니다만, 컴퓨터, 서버 등 정보통신설비의 보수점검, 교체 또는 고장, 통신두절 등 운영상 상당한 이유가 있는 경우 부득이 서비스의 전부 또는 일부를 중단할 수 있습니다.

한편, ACG는 서비스 운영 또는 개선을 위해 상당한 필요성이 있는 경우 서비스의 전부 또는 일부를 수정, 변경 또는 종료할 수 있습니다. 무료로 제공되는 서비스의 전부 또는 일부를 수정, 변경 또는 종료하게 된 경우 관련 법령에 특별한 규정이 없는 한 별도의 보상을 하지 않습니다.

이 경우 ACG는 예측 가능한 경우 상당기간 전에 이를 안내하며, 만약 예측 불가능한 경우라면 사후 지체 없이 상세히 설명하고 안내 드리겠습니다. 또한 서비스 중단의 경우에는 여러분 자신의 콘텐츠를 백업할 수 있도록 합리적이고 충분한 기회를 제공하도록 하겠습니다.

ACG는 수시로 본 약관, 계정 및 게시물 운영정책을 개정할 수 있습니다만, 관련 법령을 위배하지 않는 범위 내에서 개정할 것이며, 사전에 그 개정 이유와 적용 일자를 서비스 내에 알리도록 하겠습니다. 또한 여러분에게 불리할 수 있는 중대한 내용의 약관 변경의 경우에는 최소 30일 이전에 해당 서비스 내 공지하고 별도의 전자적 수단(전자메일, 서비스 내 알림 등)을 통해 개별적으로 알릴 것입니다.

본 약관은 한국어를 정본으로 합니다. 본 약관 또는 ACG 서비스와 관련된 여러분과 ACG와의 관계에는 대한민국의 법령이 적용됩니다. 그리고 본 약관 또는 ACG 서비스와 관련하여 여러분과 ACG 사이에 분쟁이 발생할 경우, 그 분쟁의 처리는 대한민국 '민사소송법'에서 정한 절차를 따릅니다.

적용 일자: 2023년 7월 1일
ACG 서비스와 관련하여 궁금하신 사항이 있으시면 고객센터(대표번호: 501 – 6185/ 평일 09:00~18:00)로 문의 주시기 바랍니다.
`;

const term2 = `개인정보보호법에 따라 ACG에 회원가입 신청하시는 분께 수집하는 개인정보의 항목, 개인정보의 수집 및 이용목적, 개인정보의 보유 및 이용기간, 동의 거부권 및 동의 거부 시 불이익에 관한 사항을 안내 드리오니 자세히 읽은 후 동의하여 주시기 바랍니다.

1. 수집하는 개인정보
ACG는 서비스 이용을 위해 필요한 최소한의 개인정보를 수집합니다.

회원가입 시점에 ACG가 이용자로부터 수집하는 개인정보는 아래와 같습니다.
- 회원 가입 시 필수항목으로 이메일주소(아이디), 비밀번호, 이름, 휴대전화 번호를 수집합니다. - - 서비스 이용 과정에서 이용자로부터 수집하는 개인정보는 아래와 같습니다.
- 개인정보를 수집할 경우에는 해당 개인정보 수집 시점에서 이용자에게 ‘수집하는 개인정보 항목, 개인정보의 수집 및 이용목적, 개인정보의 보관기간’에 대해 안내 드리고 동의를 받습니다.

서비스 이용 과정에서 IP 주소, 쿠키, 서비스 이용 기록, 기기정보, 위치정보가 생성되어 수집될 수 있습니다. 
구체적으로 1) 서비스 이용 과정에서 이용자에 관한 정보를 자동화된 방법으로 생성하여 이를 저장(수집)하거나, 2) 이용자 기기의 고유한 정보를 원래의 값을 확인하지 못 하도록 안전하게 변환하여 수집합니다.
서비스 이용 과정에서 위치정보가 수집될 수 있으며,
ACG에서 제공하는 위치기반 서비스에 대해서는 'ACG 위치기반서비스 이용약관'에서 자세하게 규정하고 있습니다.
이와 같이 수집된 정보는 개인정보와의 연계 여부 등에 따라 개인정보에 해당할 수 있고, 개인정보에 해당하지 않을 수도 있습니다.

2. 수집한 개인정보의 이용
ACG 서비스(모바일 웹/앱 포함)의 회원관리, 서비스 개발・제공 및 향상, 안전한 인터넷 이용환경 구축 등 아래의 목적으로만 개인정보를 이용합니다.

- 회원 가입 의사의 확인, 이용자 식별, 회원탈퇴 의사의 확인 등 회원관리를 위하여 개인정보를 이용합니다.
- 기존 서비스 제공에 더하여, 서비스 방문 및 이용기록의 분석 등 신규 서비스 요소의 발굴 및 기존 서비스 개선 등을 위하여 개인정보를 이용합니다.
- 법령 및 ACG 이용약관을 위반하는 회원에 대한 이용 제한 조치, 부정 이용 행위를 포함하여 서비스의 원활한 운영에 지장을 주는 행위에 대한 방지 및 제재, 계정도용 방지, 약관 개정 등의 고지사항 전달, 분쟁조정을 위한 기록 보존, 민원처리 등 이용자 보호 및 서비스 운영을 위하여 개인정보를 이용합니다.
- 서비스 이용기록과 접속 빈도 분석, 서비스 이용에 대한 통계 등에 개인정보를 이용합니다.
- 보안, 프라이버시, 안전 측면에서 이용자가 안심하고 이용할 수 있는 서비스 이용환경 구축을 위해 개인정보를 이용합니다.

3. 개인정보의 보관기간
회사는 원칙적으로 이용자의 개인정보를 회원 탈퇴 시 지체없이 파기하고 있습니다.
단, 이용자에게 개인정보 보관기간에 대해 별도의 동의를 얻은 경우, 또는 법령에서 일정 기간 정보보관 의무를 부과하는 경우에는 해당 기간 동안 개인정보를 안전하게 보관합니다.
이용자에게 개인정보 보관기간에 대해 회원가입 시 또는 서비스 가입 시 동의를 얻은 경우는 아래와 같습니다.
- 부정 가입 및 이용 방지
부정 이용자의 가입인증 휴대전화번호 : 탈퇴일로부터 6개월 보관
탈퇴한 이용자의 휴대전화번호(복호화가 불가능한 일방향 암호화(해시처리)) : 탈퇴일로부터 6개월 보관
통신비밀보호법 등 법령에서 일정기간 정보의 보관을 규정하는 경우는 아래와 같습니다. ACG는 이 기간 동안 법령의 규정에 따라 개인정보를 보관하며, 본 정보를 다른 목적으로는 절대 이용하지 않습니다.
- 통신비밀보호법
로그인 기록: 3개월

참고로 ACG는 ‘개인정보 유효기간제’에 따라 1년간 서비스를 이용하지 않은 회원의 개인정보를 별도로 분리 보관하여 관리하고 있습니다.

4. 개인정보 수집 및 이용 동의를 거부할 권리
이용자는 개인정보의 수집 및 이용 동의를 거부할 권리가 있습니다. 회원가입 시 수집하는 최소한의 개인정보, 즉, 필수 항목에 대한 수집 및 이용 동의를 거부하실 경우, 회원가입이 어려울 수 있습니다.
`;
