import { IconButton, Menu, MenuItem, MenuList } from "@mui/material";
import React, { useState } from "react";

const MenuComponent = ({ ...props }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClose = (e) => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);

  const handleFunction = (func) => {
    handleClose();
    func && func();
  };

  const handleClick = (event) => {
    handleClose();
    setAnchorEl(event.currentTarget);
  };
  return (
    <>
      <IconButton size="small" onClick={handleClick} sx={{ mr: 0.5 }}>
        {props.icon}
      </IconButton>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        {props.list.map((item, index) => {
          return (
            <MenuItem
              onClick={() => handleFunction(item.function)}
              key={index}
              sx={item.sx}
            >
              {item.title}
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
};

export default MenuComponent;
