// ================ COMPANY ================
// 채팅 템플릿
export const MESSAGE_UPDATE_TEMPLATE = "해당 템플릿 내용을 수정하시겠습니까?";
export const MESSAGE_DELETE_TEMPLATE = "템플릿을 삭제하시겠습니까?";
export const MESSAGE_RESTORE_TEMPLATE = "해당 템플릿을 복원하시겠습니까?";
export const MESSAGE_CREATE_TEMPLATE = "해당 템플릿을 등록하시겠습니까?";
// 검사세트
export const MESSAGE_DELETE_NORM = "검사 SET를 삭제하시겠습니까?";
export const MESSAGE_CREATE_NORM = "검사 SET를 추가하시겠습니까?";
export const MESSAGE_DISABLE_NORM = "해당 검사 SET를 미사용처리 하시겠습니까?";
export const MESSAGE_ABLE_NORM = "해당 검사 SET를 사용처리 하시겠습니까?";
export const MESSAGE_CREATE_REPORT = "레포트 템플릿을 등록하시겠습니까?";
export const MESSAGE_DELETE_REPORT = "레포트 템플릿을 삭제하시겠습니까?";
export const MESSAGE_APPLY_REPORT = "해당 레포트를 적용하겠습니까?";

// 약관
export const MESSAGE_UPDATE_TERM = "약관을 수정하시겠습니까?";
export const MESSAGE_COPY_TERM = "약관을 복사합니다.";
export const MESSAGE_DELETE_TERM = "해당 약관을 삭제하시겠습니까?";

// 담당자관리
export const MESSAGE_RESTORE_USER = "해당 담당자가 리스트에 복원됩니다.";
export const MESSAGE_CREATE_USER = "HR담당자를 추가하시겠습니까?";
export const MESSAGE_DELETE_USER = "HR담당자를 삭제하시겠습니까?";
export const MESSAGE_BACKDOOR_LOGIN = "해당 담당자로 로그인 하시겠습니까?";
export const MESSAGE_BACKDOOR_SIGNUP_EMAIL = "해당 이메일로 전송하시겠습니까?";

// 고객사
export const MESSAGE_CREATE_COMPANY = "고객사 등록을 진행하시겠습니까?";
export const MESSAGE_DELETE_COMPANY = "해당 고객사를 삭제 하시겠습니까?";
export const MESSAGE_RESTORE_COMPANY = "해당 고객사를 복원 하시겠습니까?";
export const MESSAGE_UPDATE_COMPANY = "해당 고객사 정보를 수정하시겠습니까?";

// 레이아웃 템플릿
export const MESSAGE_UPDATE_LAYOUT = "변경된 내용을 저장하시겠습니까?";

// ================ 그룹관리 ================
// 공고
export const MESSAGE_RESTORE_GROUP = "선택하신 공고를 복원하시겠습니까?";
export const MESSAGE_CREATE_GROUP = "공고를 등록하시겠습니까?";
export const MESSAGE_UPDATE_GROUP = "해당 공고 정보를 수정하시겠습니까?";
export const MESSAGE_DELETE_GROUP = "해당 공고를 삭제하시겠습니까?";
export const MESSAGE_DELETE_ALL_TEST_GROUP = "해당 공고를 삭제하시겠습니까?";
export const MESSAGE_CONFIRM_GROUP = "해당 공고를 확정하시겠습니까?";
export const MESSAGE_CANCEL_CONFIRM_GROUP = "해당 공고를 확정취소하시겠습니까?";

// 화상룸 OR 비화상룸
export const MESSAGE_CREATE_MANAGER = "감독관을 등록하시겠습니까?";
export const MESSAGE_CREATE_TESTER = "응시자를 등록하시겠습니까?";
export const MESSAGE_DOWNLOAD_TESTER_LIST = "출석부를 다운로드합니다.";
export const MESSAGE_DOWNLOAD_MARK_DATA = "해당 공고의 전체 Raw Data를 다운로드합니다.";
export const MESSAGE_SEND_MESSAGE_ALL = "해당 공고의 감독관 전체에게 발송합니다.";
export const MESSAGE_SEND_MESSAGE_PERSONAL = "메세지를 전송하시겠습니까?";
export const MESSAGE_DELETE_ROOM = "선택하신 화상룸을 삭제하시겠습니까?";
export const MESSAGE_ASSIGN_ROOM = "화상룸 배정을 진행하시겠습니까? <br/> 사전점검을 완료한 응시인원만 배정됩니다.";
export const MESSAGE_SCORING = "응시 결과처리를 진행 하시겠습니까?";
export const MESSAGE_CREATE_ROOM = "화상룸을 추가하시겠습니까?";
export const MESSAGE_UPLOAD = "업로드를 진행하시겠습니까?";
export const MESSAGE_REGIST = "등록을 진행하시겠습니까?";
export const MESSAGE_MOVE_MANAGER_PAGE = "감독관 페이지로 이동하시겠습니까?";
export const MESSAGE_MOVE_TESTER = "응시자가 해당 룸으로 이동합니다.";
export const MESSAGE_DELETE_TESTER = "응시자를 삭제하시겠습니까?";
export const MESSAGE_CALL_ALL = "일괄 전화요청 하시겠습니까?";
export const MESSAGE_UPDATE_TESTER_STATUS = "응시자 진행 상태를 수정하시겠습니까?";
export const MESSAGE_UPDATE_TESTER = "응시자 정보를 수정하시겠습니까?";
export const MESSAGE_TESTER_MARKINFO_DOWNLOAD = "해당 응시자의 Raw Data 정보를 다운로드 하시겠습니까?";
export const MESSAGE_DOWNLOAD_GROUP_REPORT = "그룹 레포트를 다운로드 합니다.";
export const MESSAGE_DOWNLOAD_AI_DATA = "AI 데이터를 다운로드 합니다.";
export const MESSAGE_DOWNLOAD_PERSONAL_REPORT = "개인 레포트를 다운로드 합니다.";
export const MESSAGE_DOWNLOAD_INTERVIEWER_REPORT = "면접관용 레포트를 다운로드 합니다.";
export const MESSAGE_DOWNLOAD_CUSTOMIZED_REPORT = "SHR 레포트를 다운로드 합니다.";
export const MESSAGE_DOWNLOAD_TESTER_STATUS_LIST = "응시자 현황 리스트를 다운로드합니다.";

// Q&A관리
export const MESSAGE_REPLY_QNA = "해당 질문에 대한 답변을 작성하시겠습니까?";
export const MESSAGE_RESTORE_QNA = "해당 Q&A를 복원하시겠습니까?";
export const MESSAGE_DELETE_QNA = "해당 Q&A를 삭제하시겠습니까?";

//USER

export const MESSAGE_UPDATE_USER = "해당 사용자의 정보를 수정하시겠습니까? \n 회원정보 수정 시 자동으로 로그아웃 됩니다.";
export const MESSAGE_REQUEST_AUTH_KEY = "해당번호로 인증번호를 요청합니다.";
export const MESSAGE_UPDATE_PASSWORD = "비밀번호를 수정하시겠습니까?";
export const MESSAGE_RESET_PASSWORD = "비밀번호 초기화를 진행하시겠습니까?";

// ================ HR관리자 ================
export const MESSAGE_APPROVE_HR = "HR담당자 가입을 승인하시겠습니까?";
export const MESSAGE_REJECT_HR = "HR담당자 가입을 거절하시겠습니까?";
export const MESSAGE_DELETE_HR = "해당 HR담당자를 삭제하시겠습니까?까?";

export const MESSAGE_SIGNUP_HR = "회원가입을 진행하시겠습니까?";

// ================ PILOT ================
export const MESSAGE_CREATE_PILOT = "PILOT 등록을 진행하시겠습니까?";
export const MESSAGE_DELETE_PILOT = "해당 PILOT을 삭제 하시겠습니까?";
export const MESSAGE_RESTORE_PILOT = "해당 PILOT을 복원 하시겠습니까?";
export const MESSAGE_UPDATE_PILOT = "해당 PILOT 정보를 수정하시겠습니까?";

// ================ 예제관리 ================
export const MESSAGE_DELETE_PRACTICE_GROUP = "선택하신 예제를 삭제하시겠습니까?";
export const MESSAGE_CREATE_PRACTICE_GROUP = "예제를 등록하시겠습니까?";
export const MESSAGE_UPDATE_PRACTICE_GROUP = "예제를 수정하시겠습니까?";
export const MESSAGE_CREATE_PRACTICE = "예제문항을 등록하시겠습니까?";
export const MESSAGE_DELETE_PRACTICE = "예제문항을 삭제하시겠습니까?";
export const MESSAGE_UPDATE_PRACTICE = "예제문항을 수정하시겠습니까?";

// ================ 문항관리 ================

export const MESSAGE_CREATE_QUESTION_GROUP = "문항을 등록하시겠습니까?";
export const MESSAGE_DELETE_QUESTION_GROUP = "문항을 삭제하시겠습니까?";
export const MESSAGE_UPDATE_QUESTION_GROUP = "문항을 수정하시겠습니까?";
