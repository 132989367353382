import { useCallback, useEffect, useRef, useState } from "react";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import { useQueryClient } from "@tanstack/react-query";
import useMutationHook from "hooks/useMutationHook";

import { Divider, FormControl, FormLabel } from "@mui/material";
import { checkIcon, errorIcon, questionIcon } from "assets/icon/Icon_modal";
import { alertModalConfig, confirmModalConfig } from "components/templates/modalConfig";
import { MESSAGE_UPDATE_TESTER, MESSAGE_UPDATE_TESTER_STATUS } from "enums/modalMessage";
import useGetQueryhr from "hooks/useGetQueryhr";
import { useMemo } from "react";
import { useRecoilValue } from "recoil";
import { groupInfoAtom, HRcompanyInfo } from "recoil/store";
import Swal from "sweetalert2";
import { autoHyphen } from "utils/autoHyphen";
import { dateFormat, dateFormatTime } from "utils/formatter/dateFormat";
export default function UpdateRoomTesterModal({ onClose, roomTesterInfo, queryKey = "roomTesterList", pilotFlag = false }) {
  const queryClient = useQueryClient();

  let testerBirth = useRef();
  let testerEmailRef = useRef();
  let testerIdRef = useRef();
  let cheatingMemoRef = useRef();
  // let testerMemoRef = useRef();

  const { companyIdx } = useRecoilValue(groupInfoAtom);
  const groupIdx = roomTesterInfo?.groupIdx;

  const [testerInfo, setTesterInfo] = useState({});

  const companyInfo = useRecoilValue(HRcompanyInfo);
  const disabledOption = useMemo(() => {
    return companyInfo.companyIdx !== 1 ? true : false;
  }, [companyInfo]);

  //* Mutation 후 이벤트
  const alertResult = useCallback((key) => {
    onClose();
    queryClient.invalidateQueries({
      queryKey: [key],
    });
  }, []);

  //* 응시자 정보 수정
  const { mutate } = useMutationHook("updateRoomTester", "HR", pilotFlag);

  //* 응시자 정보 수정 응시포기
  const { mutate: giveUpMutate } = useMutationHook("updateTesterGiveUp", "HR", pilotFlag);

  // 직군조회
  const { data: bizList } = useGetQueryhr("getBizList", {
    companyIdx: companyIdx,
  });

  // //* 검사 SET
  const { data } = useGetQueryhr(
    "testSetStatus",
    {
      groupIdx: groupIdx,
      perPage: 10,
      pageNo: 1,
    },
    groupIdx === undefined ? false : true
  );

  useEffect(() => {
    console.log("🚀 ~ useEffect ~ roomTesterInfo:", roomTesterInfo);
    roomTesterInfo &&
      setTesterInfo((prev) => ({
        ...prev,

        // 응시자 정보
        testerCell: roomTesterInfo.testerCell,
        // testerGender: roomTesterInfo.testerGender,
        testerCareerId: roomTesterInfo.testerCareerId,

        // 검사 상태
        foreignLoginYN: roomTesterInfo.foreignLoginYN,
        preExamYN: roomTesterInfo.preExamYN,
        attendanceYN: roomTesterInfo.attendanceYN,
        freePassYN: roomTesterInfo.freePassYN,
        cheatingYN: roomTesterInfo.cheatingYN,
        giveUpYN: roomTesterInfo.giveUpYN,

        // 검사 정보
        languageIdx: roomTesterInfo.languageIdx,
        bizNo: Number(roomTesterInfo.bizNo),
        normIdx: roomTesterInfo.normIdx,
      }));
  }, [roomTesterInfo]);

  const YNconvert = (refInput) => {
    if (refInput) {
      return "Y";
    } else {
      return "N";
    }
  };

  const handleCheckList = (e) => {
    const { name, checked } = e.target;
    setTesterInfo((prev) => ({ ...prev, [name]: YNconvert(checked) }));
  };

  const handleSelectList = (e) => {
    const { name, value } = e.target;
    setTesterInfo((prev) => ({ ...prev, [name]: Number(value) }));
  };

  const handleTesterCell = (e) => {
    const { value } = e.target;
    setTesterInfo((prev) => ({
      ...prev,
      testerCell: autoHyphen(value),
    }));
  };
  // const handleTesterGender = (e) => {
  //   const { value } = e.target;
  //   setTesterInfo((prev) => ({
  //     ...prev,
  //     testerGender: value,
  //   }));
  // };
  const handleSubmit = () => {
    let submit = {};

    submit.testerId = testerIdRef.current.value === "" ? null : testerIdRef.current.value;
    submit.testerBirth = testerBirth.current.value;
    submit.testerEmail = testerEmailRef.current.value === "" ? null : testerEmailRef.current.value;

    submit.cheatingMemo = cheatingMemoRef.current.value === "" ? null : cheatingMemoRef.current.value;
    // submit.testerMemo = testerMemoRef.current.value === "" ? null : testerMemoRef.current.value;
    submit = { ...submit, ...testerInfo };

    delete submit.giveUpYN;

    if (!submit.languageIdx || !submit.normIdx || !submit.bizNo) {
      return Swal.fire(
        alertModalConfig({
          text: "응시자 입력 정보를 다시 확인해 주세요.",
          title: "응시자 정보 수정",
          iconHtml: errorIcon,
          confirmButtonText: "닫기",
        })
      );
    }

    Swal.fire(
      confirmModalConfig({
        text: MESSAGE_UPDATE_TESTER,
        title: "응시자 정보 수정",
        iconHtml: questionIcon,
        confirmButtonText: "확인",
        cancelButtonText: "취소",
      })
    ).then((result) => {
      if (result.isConfirmed) {
        mutate(
          { index: roomTesterInfo.testerIdx, values: submit },

          {
            onSuccess: () => {
              Swal.fire(
                alertModalConfig({
                  text: "응시자 정보 수정이 완료되었습니다.",
                  title: "응시자 정보 수정",
                  iconHtml: checkIcon,
                  confirmButtonText: "확인",
                })
              ).then((result) => {
                if (result.isConfirmed) alertResult(queryKey);
              });
            },
          }
        );
      }
    });
  };

  const handleGiveUp = (e) => {
    const { checked, name } = e.target;

    Swal.fire(
      confirmModalConfig({
        text: MESSAGE_UPDATE_TESTER_STATUS,
        title: "응시자 시험정보 수정",
        iconHtml: questionIcon,
        confirmButtonText: "확인",
        cancelButtonText: "취소",
      })
    ).then((result) => {
      if (result.isConfirmed) {
        giveUpMutate(
          {
            testerIdx: roomTesterInfo.testerIdx,
            data: {
              [name]: checked ? "Y" : "N",
            },
          },
          {
            onSuccess: () => {
              Swal.fire(
                alertModalConfig({
                  text: "응시자 시험정보 수정이 완료되었습니다.",
                  title: "응시자 시험정보 수정",
                  iconHtml: checkIcon,
                  confirmButtonText: "확인",
                })
              ).then((result) => {
                if (result.isConfirmed) {
                  queryClient.invalidateQueries({
                    queryKey: [queryKey],
                  });
                  setTesterInfo((prev) => ({
                    ...prev,
                    [name]: checked ? "Y" : "N",
                  }));
                }
              });
            },
          }
        );
      }
    });
  };
  return (
    <>
      <DialogTitle mb={1}>
        <Grid container justifyContent={"space-between"} alignItems="center">
          <Typography variant="h5" fontWeight="bold">
            응시자 정보 수정
            <Typography component={"span"} fontWeight={700} ml={2} color="#477DA5" bgcolor="#E9F3F7" px={2} py={0.5} borderRadius={1} fontSize="1.2rem">
              {roomTesterInfo?.testerName}
            </Typography>
            <Typography component={"span"} ml={2}>
              {`응시자ID : ${roomTesterInfo?.testerCareerId || "등록된 ID가 없습니다."}`}
            </Typography>
          </Typography>
          <Box display="flex" flexDirection={"column"}>
            <Typography>{`등록일 : ${dateFormat(roomTesterInfo?.createdAt)}`}</Typography>

            <Typography>{`수정일 : ${dateFormat(roomTesterInfo?.updatedAt)}`}</Typography>
          </Box>
        </Grid>
      </DialogTitle>

      <DialogContent>
        {/* <Grid container alignItems="flex-start" px={2} mb={2}> */}
        <FormControl fullWidth>
          <FormLabel
            sx={{
              pb: 1,
              "&::before": {
                content: "'▪️ '",
              },
            }}
          >
            기본 정보
          </FormLabel>
          <Grid container columnSpacing={2} pl={2} justifyContent="space-between">
            <Grid item flexDirection="column" rowGap={0.5} xs>
              <Typography sx={labelStyle}>생년월일</Typography>
              <TextField
                fullWidth
                size="small"
                inputRef={testerBirth}
                defaultValue={roomTesterInfo?.testerBirth}
                name={"testerBirth"}
                placeholder="생년월일을 입력해 주세요."
              />
            </Grid>

            <Grid item flexDirection="column" rowGap={0.5} xs>
              <Typography sx={labelStyle}>휴대전화 번호</Typography>
              <TextField
                onChange={handleTesterCell}
                value={testerInfo.testerCell || ""}
                inputProps={{ maxLength: 13 }}
                fullWidth
                size="small"
                name={"testerCell"}
                placeholder="휴대전화 번호를 입력해 주세요."
              />
            </Grid>
            <Grid item flexDirection="column" rowGap={0.5} xs>
              <Typography sx={labelStyle}>이메일</Typography>
              <TextField
                fullWidth
                size="small"
                type="email"
                inputRef={testerEmailRef}
                defaultValue={roomTesterInfo?.testerEmail || null}
                name={"testerEmail"}
                placeholder="이메일을 입력해 주세요."
              />
            </Grid>
            <Grid item flexDirection="column" rowGap={0.5} xs>
              <Typography sx={labelStyle}>수험번호</Typography>
              <TextField fullWidth size="small" inputRef={testerIdRef} defaultValue={roomTesterInfo?.testerId || null} name={"testerIdx"} />
            </Grid>
            {/* <Grid item flexDirection="column" rowGap={0.5} xs>
              <Typography sx={labelStyle}>성별</Typography>
              <ToggleButtonGroup
                color="primary"
                value={testerInfo.testerGender}
                exclusive
                onChange={handleTesterGender}
                aria-label="Platform"
                size="small"
                sx={{
                  width: "100%",
                  justifyContent: "space-between",
                  "&>button": {
                    width: "100%",
                    fontWeight: 700,
                  },
                }}
              >
                <ToggleButton value="M">남</ToggleButton>
                <ToggleButton value="W">여</ToggleButton>
              </ToggleButtonGroup>
            </Grid> */}
          </Grid>
        </FormControl>
        <Divider sx={{ mt: 2 }} />
        <Grid item container flexWrap="nowrap">
          <Grid item xs={6} container flexDirection="column" rowGap={1.5} my={2}>
            <FormControl>
              <FormLabel
                sx={{
                  "&::before": {
                    content: "'▪️ '",
                  },
                }}
              >
                검사 정보
              </FormLabel>
            </FormControl>
            <Grid container direction="column" rowGap={2} mt={1}>
              <Stack direction={"column"} justifyContent="space-between" pl={2}>
                <Typography sx={labelStyle}>응시 언어</Typography>
                <Select fullWidth size="small" value={testerInfo.languageIdx || ""} onChange={handleSelectList} name={"languageIdx"} disabled={disabledOption}>
                  <MenuItem key={1} value={1}>
                    한국어
                  </MenuItem>
                  <MenuItem key={2} value={2}>
                    영어
                  </MenuItem>
                </Select>
              </Stack>
              <Stack direction={"column"} justifyContent="space-between" pl={2}>
                <Typography sx={labelStyle}>검사 SET</Typography>
                <Select fullWidth size="small" value={testerInfo.normIdx || ""} onChange={handleSelectList} name={"normIdx"} disabled={disabledOption}>
                  {data &&
                    data.map((item) => {
                      return (
                        <MenuItem value={item.normIdx} key={item.normIdx}>
                          {item.normName}
                        </MenuItem>
                      );
                    })}
                </Select>
              </Stack>
              <Stack direction={"column"} justifyContent="space-between" pl={2}>
                <Typography sx={labelStyle}>직군</Typography>
                <Select fullWidth size="small" value={testerInfo.bizNo || 0} onChange={handleSelectList} name={"bizNo"} disabled={disabledOption}>
                  {bizList &&
                    bizList.map((item) => (
                      <MenuItem key={item.bizNo} value={item.bizNo}>
                        {item.bizName}
                      </MenuItem>
                    ))}
                </Select>
              </Stack>
            </Grid>
          </Grid>
          <Divider flexItem orientation="vertical" sx={{ mx: 1.5 }} />
          <Grid item xs={6} container flexDirection={"column"} mt={2}>
            <FormControl>
              <FormLabel
                sx={{
                  pb: 1,
                  "&::before": {
                    content: "'▪️ '",
                  },
                }}
              >
                검사 상태
              </FormLabel>
            </FormControl>
            <Grid container flexDirection={"column"} alignItems="center" justifyContent={"center"} pl={2}>
              <Grid item container alignItems={"center"}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name={"giveUpYN"}
                      // onChange={handleCheckList}
                      checked={testerInfo?.giveUpYN === "Y" ? true : false}
                      // checked={giveUp === "Y" ? true : false}
                      onChange={handleGiveUp}
                      disabled={disabledOption}
                    />
                  }
                  label={<Typography fontWeight={700}>응시포기</Typography>}
                />
              </Grid>

              <Grid container>
                <Grid item xs={6}>
                  <Grid item container alignItems={"center"}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name={"preExamYN"}
                          onChange={handleCheckList}
                          checked={testerInfo?.preExamYN === "Y" ? true : false}
                          disabled={disabledOption}
                        />
                      }
                      label={<Typography fontWeight={700}>사전점검</Typography>}
                    />
                  </Grid>
                  <Grid item container alignItems={"center"}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name={"attendanceYN"}
                          onChange={handleCheckList}
                          checked={testerInfo?.attendanceYN === "Y" ? true : false}
                          disabled={disabledOption}
                        />
                      }
                      label={<Typography fontWeight={700}>출석</Typography>}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={6}>
                  <Grid item container alignItems={"center"}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name={"foreignLoginYN"}
                          onChange={handleCheckList}
                          checked={testerInfo?.foreignLoginYN === "Y" ? true : false}
                          disabled={disabledOption}
                        />
                      }
                      label={<Typography fontWeight={700}>해외응시</Typography>}
                    />
                  </Grid>
                  <Grid item container alignItems={"center"}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name={"freePassYN"}
                          onChange={handleCheckList}
                          checked={testerInfo?.freePassYN === "Y" ? true : false}
                          disabled={disabledOption}
                        />
                      }
                      label={<Typography fontWeight={700}>예외로그인 허용</Typography>}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item container direction="column">
                <FormControlLabel
                  control={
                    <Checkbox
                      name={"cheatingYN"}
                      onChange={handleCheckList}
                      checked={testerInfo?.cheatingYN === "Y" ? true : false}
                      disabled={disabledOption}
                    />
                  }
                  label={<Typography fontWeight={700}>부정행위</Typography>}
                />
                <TextField
                  size="small"
                  minRows={3}
                  maxRows={3}
                  multiline
                  disabled={disabledOption && testerInfo.cheatingYN === "N" ? true : false}
                  sx={{ minWidth: 210 }}
                  placeholder="내용을 입력해 주세요."
                  inputRef={cheatingMemoRef}
                  defaultValue={roomTesterInfo?.cheatingMemo}
                  name={"cheatingMemo"}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {/* <Divider /> */}
        {/* <Grid item container flexWrap="nowrap">
          <Grid item xs={12} container flexDirection="column" rowGap={1.5} mt={2}>
            <FormControl>
              <FormLabel
                sx={{
                  "&::before": {
                    content: "'▪️ '",
                  },
                }}
              >
                메모
              </FormLabel>
            </FormControl>
            <TextField
              size="small"
              minRows={3}
              maxRows={3}
              multiline
              sx={{ minWidth: 210 }}
              placeholder="내용을 입력해 주세요."
              inputRef={testerMemoRef}
              defaultValue={roomTesterInfo?.testerMemo}
              name={"testerMemo"}
            />
          </Grid>
        </Grid> */}

        <Grid container justifyContent="flex-end" pt={1}>
          <Stack p={1}>
            <Typography fontWeight="bold" borderRadius={2} color="#7e7e7e" mb={0.5} fontSize="0.9rem">
              * 최근 로그인시간
            </Typography>
            <Typography color="#7e7e7e" fontSize="0.9rem">
              {roomTesterInfo?.logAt === null ? "접속 기록이 없습니다" : `${dateFormatTime(roomTesterInfo?.logAt)}`}
            </Typography>
          </Stack>
        </Grid>
      </DialogContent>

      <DialogActions sx={{ pr: 3, pb: 3 }}>
        <Button variant={"contained"} onClick={handleSubmit}>
          확인
        </Button>
        <Button variant={"outlined"} onClick={onClose}>
          취소
        </Button>
      </DialogActions>
    </>
  );
}
const labelStyle = {
  fontWeight: "bold",
  mb: 0.5,
};
